import { mapState, mapActions, mapGetters } from "vuex";
import FormBuilder from "@/components/WorkflowBuilder/FormBuilder";
import QuestionForm from "@/components/QuestionForm";
import FilterKeyword from "@/components/FilterKeyword";
import { nanoid } from "nanoid";
import PublishForm from "@/components/PublishForm.vue";
import CollaboratorsDialog from "@/components/CollaboratorsDialog";
import copyWorkflow from '@/services/copyWorkflow'
import { getDateFormat } from "@/utils/getDateFormat.util"
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
import { getEnvironment } from '@/services/gcloud/getProject'
import mvFrmToStg from '@/services/mvFrmToStg.service'
import copyStorage from "../../services/copy-storage.service";

export default {
  mixins: [adminCheckerMixin],

  components: {
    FilterKeyword,
    FormBuilder,
    QuestionForm,
    PublishForm,
    CollaboratorsDialog,
  },

  watch: {
    isAdmin: (newVal) => {
      if(newVal === false && this?.$router) {
        this.$router.push('/')
      }
    },

    teapErrorMsg: (newVal) => {
      if(newVal.length > 0) 
        this.setAlert({ type: 'error', message: teapErrorMsg })
    }
  },

  data() {
    return {
      filteredFormsList: [],

      shareFormMetadata: {
        collaboratorDialog: false,
        publishDialog: false,
        id: "",
        title: "",
        index: null,
        access: [],
        collaborators: [],
        userToAdd: null,
        status: null,
        state: "",
      },

      deleteFormMetadata: {
        dialog: null,
        form: undefined,
        index: null,
      },

      copyFormMetadata: {
        dialog: null,
        resolve: null,
      },

      activeForm: {
        name: "",
        description: "",
        fields: [],
        access: [],
      },

      errorMessages: "",

      formHasErrors: false,

      isNewForm: false,
    };
  },

  computed: {
    ...mapGetters(["getStates", "getFirebaseConfig"]),

    ...mapGetters("profile", ["userInfo"]),

    ...mapState(["forms"]),

    ...mapGetters('workflowTypes', ['typeByState']),

    ...mapGetters("teap", {
      "teapErrorMsg": "errorMsg"
    }),

    env() {
      return getEnvironment()
    },

    getDate() {
      return (dt) => getDateFormat(dt,'MM/DD/YYYY hh:mm A')
    },

    isPending() {
      return (status) => status === "Pending"
    },

    isApproved() {
      return (status) => status === "Approved"
    },

    isPublished() {
      return (status) => status !== ""
    },

    isOwner() {
      return (owner) => owner === this.userInfo.email
    },

    publishBtnTitle() {
      return (status) => this.isApproved(status) ? 'Republish' : 'Publish'
    },

    // Needed to be able to filter the forms and then properly reference them
    formsWithOriginalIndex() {
      return this.forms.map((el, index) => {
        el.originalIndex = index;
        return el;
      });
    },

    filteredForms: {
      get: function () {
        const filteredData = (this.filteredFormsList.length === 0) ? this.formsWithOriginalIndex : this.filteredFormsList
        return filteredData.sort((a,b) => a.name.localeCompare(b.name, 'en', { ignorePunctuation: true }))
      },
      set: function (newFilteredFormsList) {
        this.filteredFormsList = newFilteredFormsList;
      },
    },

    canPublish() {
      return (
        this.shareFormMetadata.state !== undefined &&
        this.shareFormMetadata.stakeholders?.length > 0
      );
    },

    isStakeHolder() {
      return (collaborator) =>
        this.shareFormMetadata.stakeholders.findIndex(
          (s) => s.email === collaborator
        ) >= 0;
    },

    showBtn() {
      return (owner, haveDuplicates) =>
          Boolean(this.isOwner(owner) && !haveDuplicates);
    },

    disableBtn() {
      return (owner, status) => Boolean(!this.isOwner(owner) || status === 'Pending')
    },

    stakeHolders() {
      return this.shareFormMetadata.stakeholders;
    },
  },

  created() {
    this.$store.dispatch("coupons/resetState", null, { root: true }),
    this.$store.dispatch("statisticsLogic/resetState", null, { root: true })
    this.setForms();
  },

  mounted() {
    this.shareFormMetadata.state = this.userInfo.address_state;
    this.setPublishedWorkflows();
  },

  methods: {
    ...mapActions("alert", ["setAlert"]),

    ...mapActions([
      "updateFormAccessAndStakeHolders",
      "setForms",
      "removeForms",
      "setFormBuilderActiveForm",
      "toggleFormBuilderDialog",
      "toggleLoadingOverlayStatus",
    ]),

    ...mapActions("confirmation", ["confirm"]),

    ...mapActions("workflow", ["rejectWorkflow"]),

    ...mapActions("workflowPublished", ["setPublishedWorkflows", "deletePublishedWorkflow"]),

    async migrateForm(formId) {
      this.confirm({
        title: 'Are you sure you want to migrate this workflow to stage?',
        accepted: async () => {
          await this.toggleLoadingOverlayStatus(true)
          const dataResp = await mvFrmToStg(formId)
          const copyResp = await copyStorage({
            srcBucket: 'simple-ending.appspot.com',
            srcPath: `workflows-in-edit/${formId}`,
            targetBucket: 'simpleending-stage.appspot.com',
            targetPath: 'workflows-in-edit'
          })
          await this.toggleLoadingOverlayStatus(false)
          const success = dataResp.status === 200 && copyResp.status === 200
          await this.setAlert({
            type: success ? 'success' : 'error',
            message: success ? 'Successfully migrated to stage' : 'Sorry could not migrate to stage'
          })
        }
      })
    },

    hasCounties(form) {
      return form?.useCounties && form?.counties?.length > 0 || false
    },

    showCounties(formId) {
      const countyEl = document.querySelector(`#form-counties-${formId}`)
      countyEl.style.height = countyEl.style.height === 'fit-content' ? '30px': 'fit-content'
    },

    getLastModifiedDate(dt) {
      return getDateFormat(dt,'MM/DD/YYYY hh:mm A')
    },

    updateFilteredForms(filteredArray) {
      this.filteredForms = filteredArray.length === 0 ? this.forms : filteredArray;
    },

    async copyForm(id) {
      this.$store
        .dispatch("setWorkflowDuplicationStatus", true)
        .then(() => {
          // Activate dialog to get user choice about keeping collaborators
          this.activateCopyWorkflowDialog().then(async (choice) => {
            let keepCollaborators = choice === "keep";
            // const duplicateWorkflow =
            //   this.$store.getters.getFirebaseConfig.functions.httpsCallable(
            //     "copyWorkflow"
            //   );
              copyWorkflow({
                workflowId_original: id,
                workflowId_new: nanoid(20),
                keepCollaborators: keepCollaborators,
                userEmail: this.userInfo.email
              }).then(async () => {
                await this.$store.dispatch(
                  "setWorkflowDuplicationStatus",
                  false
                );
                setTimeout(async () => {
                  await this.setForms();
                  await this.$store.dispatch(
                    "toggleLoadingOverlayStatus",
                    false
                  );
                }, 2000);
              }).catch((error) => {
                this.$store.dispatch("setWorkflowDuplicationStatus", false);
                this.$store.dispatch("toggleLoadingOverlayStatus", false);
                throw error
              });
          });
        })
        .catch((error) => {
          this.$store.dispatch("setWorkflowDuplicationStatus", false);
          throw error
        });
    },

    activateCopyWorkflowDialog() {
      return new Promise(async (resolve) => {
        this.copyFormMetadata.dialog = true;
        this.copyFormMetadata.resolve = resolve;
      });
    },

    removeMeFromCollaboratorList(form) {
      this.confirm({
        title: 'Are you sure you want to remove yourself as a collaborator of this workflow?',
        accepted: () => {
          const accessIndex = form.access.findIndex(a => a === this.userInfo.email)
          form.access.splice(accessIndex, 1);
          const stakeHolderIndex = form.stakeholders.findIndex(
            (s) => s.email === this.userInfo.email
          );
          form.stakeholders.splice(stakeHolderIndex, 1);
          const shareData = {
            id: form.id,
            index: form.originalIndex,
            access: form.access,
            stakeholders: form.stakeholders,
          };
          this.updateFormAccessAndStakeHolders(shareData)
          this.forms.splice(form.originalIndex, 1)
        }
      })
    },

    handleCollaboratorsChoice(choice) {
      switch (choice) {
        case "keep":
          this.copyFormMetadata.resolve("keep");
          break;
        case "remove":
          this.copyFormMetadata.resolve("remove");
          break;
      }
      this.copyFormMetadata.dialog = false;
      this.toggleLoadingOverlayStatus(true);
    },

    async editForm(index) {
      const form = this.forms[index]
      if(form?.state && form?.type) {
        const checkTypes = this.typeByState(form.state)
      }
      await Promise.all([
        this.toggleLoadingOverlayStatus(true),
        this.setFormBuilderActiveForm(form)
      ])
      this.toggleFormBuilderDialog(true);
      await this.toggleLoadingOverlayStatus(false);
    },

    shareFormPopup(index) {
      this.shareFormMetadata.id = this.forms[index].id;
      this.shareFormMetadata.title = this.forms[index].name;
      this.shareFormMetadata.index = index;
      this.shareFormMetadata.collaboratorDialog = true;
      this.shareFormMetadata.publishDialog = false;
      this.shareFormMetadata.access = this.forms[index].access;
      this.shareFormMetadata.collaborators =
        this.forms[index].collaborators || [];
      this.shareFormMetadata.stakeholders =
        this.forms[index].stakeholders || [];
    },

    setStakeHolder(event, email) {
      if (event.target.checked) {
        const index = this.shareFormMetadata.stakeholders.findIndex(
          (s) => s.email === email
        );
        if (index < 0) {
          this.shareFormMetadata.stakeholders.push({ email, status: "" });
        }
      } else {
        this.deleteStakeHolder(email);
      }
    },

    deleteStakeHolder(email) {
      this.confirm({
        title: 'Are you sure you want to remove this stake holder?',
        accepted: () => {
          const index = this.shareFormMetadata.stakeholders.findIndex(
            (s) => s.email === email
          );
          if (index >= 0) {
            this.shareFormMetadata.stakeholders.splice(index, 1);
            this.$forceUpdate();
          }
        }
      })
    },

    showPublishDialog(index) {
      this.shareFormMetadata.id = this.forms[index].id;
      this.shareFormMetadata.title = this.forms[index].name + ': ' + this.forms[index].state + ' - ' + (this.forms[index]?.childrenOptions || 'both') + ' children'
      this.shareFormMetadata.index = index; 
      this.shareFormMetadata.collaboratorDialog = false;
      this.shareFormMetadata.stakeholders = this.forms[index].stakeholders || [];
      this.shareFormMetadata.state = this.forms[index].state || this.userInfo.address_state;
      this.shareFormMetadata.type = this.forms[index]?.type || 'Divorce'
      this.shareFormMetadata.childrenOptions = this.forms[index]?.childrenOptions || 'N/A'

      this.shareFormMetadata.stakeholders.map((sh, i) => {
        if (typeof sh === "string") {
          this.shareFormMetadata.stakeholders[i] = { email: sh, status: "" };
        }
      });

      this.shareFormMetadata.publishDialog = true;
    },

    deleteFormPopup(index) {
      this.deleteFormMetadata.form = this.forms[index];
      this.deleteFormMetadata.index = index;
      this.deleteFormMetadata.dialog = true;
    },

    removePublishedWorkflow(form) {
      this.confirm({
        title: 'Are you sure you want to unpublish this document?',
        accepted: async () => {
          this.toggleLoadingOverlayStatus(true)
          const publishedIds = (await this.getFirebaseConfig.db.collection('workflows-published').where('parentId','==',form.id).get()).docs.map(f => f.id)
          const toDelete = publishedIds.map(id => this.deletePublishedWorkflow({ id, collection: 'workflows-published' }))

          await Promise.all(toDelete)
          form.status = ""
          form.statusDateStamp = ""
          await this.rejectWorkflow({
              id: form.id,
              status: "",
            });          
          this.toggleLoadingOverlayStatus(false)
        }
      })
    },

    async deleteForm() {
      await this.toggleLoadingOverlayStatus(true)
      const deleteFormMetadata = {
        id: this.deleteFormMetadata.form.id,
        index: this.deleteFormMetadata.index,
        form: this.deleteFormMetadata.form,
      };

      await this.removeForms(deleteFormMetadata);

      this.deleteFormMetadata.dialog = false;
      this.deleteFormMetadata.index = null;
      this.deleteFormMetadata.form = undefined;
      await this.toggleLoadingOverlayStatus(false)
    },
  },
}