import { mapGetters, mapActions } from 'vuex'
import rulesMixin from "@/mixins/rules.mixin";
const { getDateFormat } = require('@/utils/getDateFormat.util')

export default {
  mixins: [rulesMixin],

  data() {
      return {
          userFormValid: false,
          loading: false,
          coupon: {
              amount: 1,
              campaign: false,
              code: '',
              count: 0,
              limit: 0,
              payall: 'both',
              type: 'percent',
              startDate: getDateFormat(new Date(), 'MM/DD/YYYY'),
              endDate: ''
          },
          campaignItems: [
              {
                  value: false,
                  text: 'No'
              },
              {
                  value: true,
                  text: 'Yes'
              },
          ]
      }
  },

  props: {
      operation: {
          Type: String,
          default: 'add'
      }
  },

  watch: {
      currentCoupon: {
          handler: function (newVal) {
              this.coupon = newVal
          },
          deep: true,
          immediate: true
      }
  },

  computed: {
      ...mapGetters("coupons", ["currentCoupon", "coupons"]),

      showPercent() {
          return this.currentCoupon.type === 'percent' ? '%' : ''
      }
  },

  methods: {
      ...mapActions("coupons", [
          "setCurrentCoupon",
          "updateCoupon",
          "addCoupon",
          "handleDuplicates"
      ]),

      async handleCouponCode() {
          this.loading = true
          await this.handleDuplicates()
          this.coupon.limit = +this.coupon.limit
          await this.updateCoupon(this.coupon)
          this.loading = false
          this.$emit('saved')
      },

      async handleCampaignChange() {
          if(this.currentCoupon.campaign === false) return
          const formerCampaigns = this.coupons.filter(c => c.code != this.currentCoupon.code && c.campaign === true)
          const toUpdate = []
          for(let c = 0; c < formerCampaigns.length; c++) {
              formerCampaigns[c].campaign = false
              toUpdate.push(this.updateCoupon(formerCampaigns[c]))
          }
          await Promise.all(toUpdate)
      }
  }  
}