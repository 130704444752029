<template>
  <div style="margin-top:40px">
    <h3 style="margin-left:20px">Workflow Types</h3>
    <v-btn @click="typeCreateDialogClick">Add</v-btn>
    <div style="margin: 0 40px;">
      <v-data-table
        :headers="headers"
        :items="types"
        :loading="dataLoading"
        loading-text="Loading ... Please wait"
        :items-per-page="50"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
              icon
              color="primary"
              @click="typeUpdateDialogClick(item)"
          >
              <v-icon
                  color="primary"
                  title="Update Type"
                  small
              >
                  mdi-pencil
              </v-icon>
          </v-btn>
          <v-btn
              icon
              color="primary"
              :loading="(item.id === deleteLoading)"
              @click="typeDeleteClick(item)"
          >
              <v-icon
                  color="primary"
                  title="Delete Type"
                  small
              >
                  mdi-delete-forever
              </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title style="position:relative">
          {{ actionTitle }}
          <v-btn
            style="margin-left:1px;position:absolute;top:0;right:0;"
            icon
            @click="cancelDialogClick"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>          
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form v-model="userFormValid">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="currentWfType.title"
                    label="Title"
                    :rules="[rules.required]"
                    :disabled="actionType === 'edit'"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="currentWfType.states"
                    label="States"
                    chips
                    multiple
                    :deletable-chips="true"
                    :rules="[rules.required]"
                    :items="getStates"
                  />
                </v-col>
              </v-row>              
              <v-row>
                <v-col>
                  <v-select
                    v-model="currentWfType.active"
                    label="Active?"
                    :rules="[rules.required]"
                    :items="['Yes', 'No']"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="cancelDialogClick"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="formLoading"
            :disabled="!userFormValid"
            @click="handleDialogSave()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import workflowTypesMixin from '@/mixins/workflowTypes.mixin';

export default {
  name: 'WorkflowTypes',

  mixins: [workflowTypesMixin]
}
</script>