import { mapGetters, mapActions } from 'vuex'
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  mixins: [adminCheckerMixin],

  data() {
      return {
          tab: 0,
          currentCategory: '',
      }
  },

  created() {
      Promise.all([
          this.getUsers(),
          this.getDbProfiles()
      ])
  },

  computed: {
      ...mapGetters(['adminNavItems']),

      ...mapGetters("profile", ["userInfo"]),

      categories() {
          return this.adminNavItems.reduce((r,a) => {
              r[a.category] = r[a.category] || []
              r[a.category].push(a)
              return r
          }, Object.create(null))
      }
  },

  methods: {
      ...mapActions("users", ["getDbProfiles", "getUsers"]),

      redirectTo(name) {
          this.$router.push({ name })
      }
  }  
}