import Vue from 'vue';
import Router from 'vue-router';
import store from '@/stores';
import getErrorPage from '@/services/getErrorPage.service'

import {
  NotFound,
  Home,
  RunDbScripts,
  Users,
  ManageWorkflows,
  About,
  SignIn,
  CreateUser,
  UserProfile,
  LegalFormGuide,
  PetitionerGuide,
  RespondentGuide,
  WorkflowFormPreview,
  PayPalTest,
  PayPalSuccess,
  PayPalCancel,
  PendingApprovals,
  ApprovalForm,
  SharedForm,
  SurveyDetail,
  EmailForm,
  BackupDatabase,
  PayForForm,
  VerifyUser,
  RespondentWait,
  CouponManager,
  Page503,
  Admin,
  Practice,
  ForgotPassword,
  Error,
  WorkflowTypes,
  StateCounties
} from '@/views';

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

const authCheck = (to, from, next) => {
  const { auth } = store.getters.getFirebaseConfig
  auth.onAuthStateChanged(user => {
    if (user) {
      next()
    } else {
      next({ name: 'sign-in', query: { redirect: to.path } });
    }
  });
}

const errorCheck = async (to, from, next) => {
  const show503 = (await getErrorPage()).data
  if(show503 === true) {
    next({ name: '503-page' })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: errorCheck
    },
    {
      path: '/error',
      name: 'error',
      component: Error
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: About,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/503',
      name: '503-page',
      component: Page503,
    },
    {
      path: '/wait',
      name: 'wait',
      component: RespondentWait
    },
    {
      path: '/practice',
      name: 'practice',
      component: Practice
    },
    {
      path: '/workflow-types',
      name: 'workflow-types',
      component: WorkflowTypes,
      beforeEnter: authCheck
    },
    {
      path: '/legal-form-guide',
      name: 'legal-form-guide',
      component: LegalFormGuide,
      beforeEnter: authCheck
    },
    {
      path: '/coupon-manager',
      name: 'coupon-manager',
      component: CouponManager,
      beforeEnter: authCheck
    },
    {
      path: '/legal-form-guide/divorce-forms/:id',
      name: 'legal-form-guide-doc-id',
      component: PetitionerGuide,
      beforeEnter: authCheck
    },
    {
      path: '/shared-forms',
      name: 'shared-forms',
      component: SharedForm,
      beforeEnter: authCheck
    },
    {
      path: '/run-db-scripts',
      name: 'run-db-scripts',
      component: RunDbScripts,
      beforeEnter: authCheck
    },
    {
      path: '/survey-detail/:id',
      name: 'survey-detail',
      component: SurveyDetail,
      beforeEnter: authCheck
    },
    {
      path: '/respondent-guide/:id',
      name: 'respondent-guide',
      component: RespondentGuide,
      beforeEnter: authCheck
    },
    {
      path: '/workflow-form-preview',
      name: 'workflow-form-preview',
      component: WorkflowFormPreview,
      beforeEnter: authCheck
    },
    {
      path: '/pending-approvals',
      name: 'pending-approvals',
      component: PendingApprovals,
      beforeEnter: authCheck
    },
    {
      path: '/pay-for-form/:formId/:userId/:type/:level',
      name: 'pay-for-form',
      component: PayForForm,
      beforeEnter: authCheck
    },
    {
      path: '/pending-approvals/:id',
      name: 'approval-form',
      component: ApprovalForm,
      beforeEnter: authCheck
    },
    {
      path: '/backup-database',
      name: 'backup-database',
      component: BackupDatabase,
      beforeEnter: authCheck
    },
    {
      path: '/manage-workflows',
      name: 'manage-workflows',
      component: ManageWorkflows,
      beforeEnter: authCheck
    },
    {
      path: '/manage-users',
      name: 'manage-users',
      component: Users,
      beforeEnter: authCheck
    },
    {
      path: '/user-profile',
      name: 'user-profile',
      component: UserProfile,
      beforeEnter: authCheck
    },
    {
      path: '/verify-user',
      name: 'verify-user',
      component: VerifyUser,
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      beforeEnter: authCheck
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: SignIn,
      props: true,
    },
    {
      path: '/create-user',
      name: 'create-user',
      component: CreateUser,
    },
    {
      path: '/paypal/cancel',
      name: 'paypal-cancel',
      component: PayPalCancel,
    },
    {
      path: '/paypal/success',
      name: 'paypal-success',
      component: PayPalSuccess,
    },
    {
      path: '/paypal/test',
      name: 'paypal-test',
      component: PayPalTest,
    },
    {
      path: '/email-form',
      name: 'email-form',
      component: EmailForm,
    },
    {
      path: "/statistics",
      name: "statistics",
      component: () => import(/* webpackChunkName: "statistics"*/ "../views/Statistics"),
      beforeEnter: authCheck,
    },
    {
      path: '/state-counties',
      name: 'state-counties',
      component: StateCounties,
      beforeEnter: authCheck
    },
    {
      path: "/child-support-calculator",
      name: "ChildSupportCalculator",
      component: () => import(/* webpackChunkName: "child-support-calculator"*/ "../views/ChildSupportCalculator"),
      beforeEnter: authCheck,
    },
    {
      path: "/user-workflow-data",
      name: "user-workflow-data",
      component: () => import(/* webpackChunkName: "child-support-calculator"*/ "../views/UserWorkflowData"),
      beforeEnter: authCheck,
    },
    {
      path: "/paywall-prices",
      name: "PaywallPrices",
      component: () => import(/* webpackChunkName: "child-support-calculator"*/ "../views/PaywallPrices"),
      beforeEnter: authCheck,
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFound,
    }
  ]
});
