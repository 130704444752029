<template>
  <v-container>
    <v-card class="v-dialog--underappbar">
      <v-card-actions>
        <v-btn outlined @click="$router.push('/pending-approvals').catch(() => {})">
          <v-icon>mdi-chevron-left</v-icon>
          Back to Pending Approvals
        </v-btn>

        <v-row v-if="isPending">
          <v-col cols="6" style="display:flex; justify-content:space-between; align-items: center; padding-left: 10%;">
            <v-btn color="error" @click="rejected = true">
              <span class="pr-2">Reject</span>
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn color="success" @click="handleChoice('Approved')">
              <span class="pr-2">Approve</span>
              <v-icon>check</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" v-if="!isOwner">
            <v-btn
              title="Remove me from this workflow"
              outlined
              @click="removeMeAsStakeholder"
            >
              <v-icon>person_remove</v-icon>
              &nbsp;
              Remove me as stakeholder
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12">
            {{ myStatus }}
          </v-col>
        </v-row>
      </v-card-actions>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <b>State: </b> {{ form.state }} <br />
            <div
              v-if="getPublishedWorkflowById(form)"
              class="text-danger"
              style="color:white;"
            >
              This state already has a form assigned to it.
            </div>
          </v-col>
          <v-col>
            <b>Owner: </b> {{ userInfo.full_name }}<br />
            <b>{{ form.status }}</b> on {{ statusDateStamp }}
          </v-col>
          <v-col>
            <b>Collaborators:</b><br />
            <div v-for="(collaborator, i) in collaborators" :key="i">
              {{ collaborator }}
            </div>
          </v-col>
          <v-col>
            <b>Stakeholders:</b><br />
            <div v-for="(sh, i) in form.stakeholders" :key="i">
              {{ sh.email }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-text>
        <v-tabs v-model="activeTab" centered icons-and-text>
          <v-tabs-slider />
          <v-tab>
            Form Preview
            <v-icon>description</v-icon>
          </v-tab>
          <v-tab>
            Rule Configuration
            <v-icon>rule</v-icon>
          </v-tab>
          <v-tabs-items v-model="activeTab">
            <v-tab-item>
              <v-card class="v-dialog--underappbar">
                <v-card-text>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12" md="6">
                        <FormBuilderPreview
                          :form="getWorkflow"
                          :read-only="true"
                          :current-survey-tab="currentSurveyTab"
                          @update-current-survey-tab="updateCurrentSurveyTab"
                          @update-current-page="setCurrentPage"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                          v-model="activeDocId"
                          :items="documents"
                          label="Documents"
                          item-value="uid"
                          item-text="name"
                          solo
                          @change="handleDocChange"
                        />
                        <FileRenderer :pdf-file="getDocumentFile">
                          <template #card-title>Document Preview</template>
                        </FileRenderer>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <RulesBuilder
                :read-only="true"
                :form="form"
                :doc-index="activeDocIndex"
                :questionList="fields"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-dialog v-model="rejected" :retain-focus="false" max-width="800">
          <v-card>
            <v-card-title class="headline">
              Why do you want to reject this form request?
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-model="explanation"
                counter
                label="Please explain why you are rejecting this?"
                :rules="[rules.min, rules.max, rules.required]"
                outlined
              />
            </v-card-text>
            <v-card-actions>
              <v-btn @click="rejected = false"> Cancel</v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="explanation.length < 100"
                @click="handleChoice('Rejected')"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FormBuilderPreview from "@/components/FormBuilderPreview";
import FileRenderer from "@/components/FileRenderer";
import RulesBuilder from "@/components/WorkflowBuilder/RulesBuilder";
import pdfMixin from "@/mixins/pdf.mixin";
import sendEmailUtil from '@/utils/send-email.util'
import { getDateFormat } from "@/utils/getDateFormat.util"

export default {
  name: "ApprovalForm",

  components: {
    FormBuilderPreview,
    FileRenderer,
    RulesBuilder,
  },

  mixins: [pdfMixin],

  data() {
    return {
      activeTab: "",
      defaultDocument: {},
      rejected: false,
      explanation: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) =>
          value.length >= 100 || "Please enter at least 100 characters.",
        max: (value) =>
          value.length <= 2000 || "Please enter no more than 2000 characters.",
      },
    };
  },

  computed: {
    ...mapState({
      firebaseConfig: "firebaseConfig",
    }),

    ...mapGetters(["baseUrl", "getFormById"]),

    ...mapGetters({
      getWorkflow: "publishGuide/getWorkflow",
      getActiveDocId: "publishGuide/getActiveDocId",
      getActiveDocIndex: "publishGuide/getActiveDocIndex",
      getDocumentFile: "publishGuide/getDocumentFile",
    }),

    ...mapGetters("profile", ["userInfo"]),

    ...mapGetters("workflowPublished", ["getPublishedWorkflowById","getPublishedWorkflowByState"]),

    statusDateStamp() {
      return getDateFormat(this.form.statusDateStamp,'MM/DD/YYYY h:mm A')
    },

    form() {
      return this.getWorkflow;
    },

    isOwner() {
      return this.userInfo.email === this.form.owner
    },

    documents() {
      if (this.getWorkflow && this.getWorkflow?.docs) {
        return this.getWorkflow.docs;
      } else {
        return [];
      }
    },

    fields() {
      if (this.getWorkflow && this.getWorkflow?.fields) {
        return this.getWorkflow.fields;
      } else {
        return [];
      }
    },

    activeDocId: {
      get() {
        return this.getActiveDocId;
      },
      set(docId) {
        this.$store.dispatch("publishGuide/setActiveDocId", docId);
      },
    },

    activeDocIndex: {
      get() {
        return this.getActiveDocIndex;
      },
      set(docIndex) {
        this.toggleLoadingOverlayStatus(true);
        this.$store.dispatch("publishGuide/setActiveDocIndex", docIndex).then(() => {
          this.toggleLoadingOverlayStatus(false);
        })
      },
    },

    isPending() {
      if (this.form) {
        if (this.form.stakeholders === undefined) return false;
        const my = this.form.stakeholders.find(
          (sh) => sh.email === this.userInfo.email
        );
        return this.form.status === "Pending" && my.status === "Pending";
      } else {
        return false;
      }
    },

    myStatus() {
      if (this.form) {
        if (this.form.stakeholders === undefined) return "Loading ...";
        const my = this.form.stakeholders.find(
          (sh) => sh.email === this.userInfo.email
        );
        return `You ${my.status} this request on ${getDateFormat(my.statusDateStamp,'MM/DD/YYYY hh:mm A')}`;
      } else {
        return "";
      }
    },

    collaborators() {
      return this?.form?.access || [];
    },
  },

  watch: {
    form: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.rejected = false;
          this.toggleLoadingOverlayStatus(false);
        }
      },
    },
  },

  created() {
    this.$store.dispatch("toggleLoadingOverlayStatus", true);
    this.$store.dispatch("setForms").then(() => {
      const id = this.$route.params.id;
      const currentForm = Object.assign({}, this.getFormById(id));
      this.$store
        .dispatch("setFormBuilderActiveForm", currentForm)
        .then(() => {
          this.setWorkflow({ workflow: currentForm }).then(() => {
            this.toggleLoadingOverlayStatus(false);
          });
        })
    });
  },

  beforeDestroy() {
    this.$store.dispatch("publishGuide/resetPublishGuide");
  },

  methods: {
    ...mapActions("workflow", [
      "publishWorkflow",
      "rejectWorkflow",
      "updateStakeholderStatus",
    ]),

    ...mapActions({
      setForms: "setForms",
      setWorkflow: "publishGuide/setWorkflow",
      updateFormAccessAndStakeHolders: "updateFormAccessAndStakeHolders",
      toggleLoadingOverlayStatus: "toggleLoadingOverlayStatus",
    }),

    ...mapActions("confirmation", ["confirm"]),

    handleDocChange(uid) {
      // Updates the doc index when the currently selected document is changed
      const docIndex = this.form.docs.findIndex((doc) => doc.uid === uid);
      this.activeDocIndex = docIndex;
    },

    removeMeAsStakeholder() {
      this.confirm({
        title: 'Are you sure you want to remove yourself as a stakeholder?',
        accepted: () => {
          const stakeHolderIndex = this.form.stakeholders.findIndex(
            (s) => s.email === this.userInfo.email
          );
          this.form.stakeholders.splice(stakeHolderIndex, 1)
          const shareData = {
            id: this.form.id,
            index: this.form.originalIndex,
            stakeholders: this.form.stakeholders,
          };
          this.updateFormAccessAndStakeHolders(shareData)
          this.$router.push('/pending-approvals').catch(() => {})
        }
      })
    },

    updateFormStatus(choice, todayStr) {
      return new Promise((resolve, reject) => {
        let allApproved = true;
        this.firebaseConfig.db
          .collection("forms")
          .doc(this.form.id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let formData = doc.data();
              formData.stakeholders.forEach((sh, i) => {
                if (this.userInfo.email === sh.email) {
                  this.form.stakeholders[i].status = choice;
                  this.form.stakeholders[i].statusDateStamp = todayStr;
                } else {
                  this.form.stakeholders[i] = sh;
                }

                if (this.form.stakeholders[i].status !== "Approved") {
                  allApproved = false;
                }
              });

              if (allApproved) {
                this.form.status = choice;
                this.form.statusDateStamp = todayStr;

                this.publishWorkflow({
                  id: this.form.id,
                  state: this.form.state,
                });
              } else if (choice === "Rejected") {
                this.rejectWorkflow({
                  id: this.form.id,
                  status: "",
                });
              } else {
                this.updateStakeholderStatus({
                  id: this.form.id,
                  stakeholders: this.form.stakeholders,
                });
              }
              this.toggleLoadingOverlayStatus(false);
              resolve();
            } else {
              this.$logger.warn("No such document!");
              this.toggleLoadingOverlayStatus(false);
              reject();
            }
          })
          .catch((error) => {
            reject(`get document error: ${error}`);
          });
      });
    },

    handleChoice(choice) {
      let stateFormFound = this.getPublishedWorkflowById(this.form);
      if(!stateFormFound) {
        stateFormFound = this.getPublishedWorkflowByState(this.form);
      }

      const baseUrl = location.original;
      if (choice === "Approved" && stateFormFound) {
        this.confirm({
          title: `The state of ${this.form.state} already has a divorce form of type ${this.form.type} with children options of ${this.form.childrenOptions}.\n\nAre you sure you want to proceed?`,
          accepted: () => makeChoice()
        })
      } else {
        makeChoice()
      }

      const makeChoice = () => {
        this.toggleLoadingOverlayStatus(true);
        const todayStr = Date.now()

        let choiceHandlerPromise = new Promise((resolve) => {
          if (choice === "Rejected") {
            this.form.status = choice;
            this.form.stakeholders.map((sh, i) => {
              if (this.userInfo.email === sh.email) {
                this.form.stakeholders[i].status = choice;
                this.form.stakeholders[i].statusDateStamp = todayStr;
              }
            });
            this.rejected = false;
            resolve();
          } else {
            this.toggleLoadingOverlayStatus(true);
          }

          this.updateFormStatus(choice, todayStr);
        });

        choiceHandlerPromise
          .then(async () => {
            const subject = `Email sent to ${this.form.owner} with status of ${choice}`;
            const type = choice === 'Rejected' ? 'disagreement' : 'agreed'
            await sendEmailUtil(baseUrl, this.form.owner, type, subject)
              .then(() => {
                this.toggleLoadingOverlayStatus(false);
              })         
            this.rejected = false;

            const { id, status, statusDateStamp, stakeholders } = this.form;
            this.updateFormAccessAndStakeHolders({
              id,
              status,
              statusDateStamp,
              stakeholders,
            });
            this.form.status = choice;
          })
      } 
    },
  },
};
</script>
