<template>
  <div style="margin-top:40px">
    <h3 style="margin-left:20px">Counties By State</h3>
    <v-btn @click="stateCountyCreateClick">Add</v-btn>
    <div style="margin: 0 40px;">
      <v-data-table
        :headers="headers"
        :items="states"
        :loading="dataLoading"
        loading-text="Loading ... Please wait"
        :items-per-page="50"
      >
        <template v-slot:item.counties="{ item }">
          {{ formatCounties(item.counties) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div style="display:flex;flex-direction: row;">
            <v-btn
              icon
              color="primary"
              @click="stateCountyUpdateClick(item)"
            >
                <v-icon
                    color="primary"
                    title="Update Type"
                    small
                >
                    mdi-pencil
                </v-icon>
            </v-btn>
            <v-btn
                icon
                color="primary"
                :loading="(item.id === deleteLoading)"
                @click="stateCountyDeleteClick(item.state)"
            >
                <v-icon
                    color="primary"
                    title="Delete Type"
                    small
                >
                    mdi-delete-forever
                </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-if="currentRecord"
      v-model="dialog"
      max-width="500"
      :retain-focus="false"
    >
      <v-card-title style="position:relative">
        {{ actionTitle }}
        <v-btn
          style="margin-left:1px;position:absolute;top:0;right:0;"
          icon
          @click="cancelDialogClick"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>          
      </v-card-title>
      <v-card-text>
        <v-form v-model="userFormValid">
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="currentRecord.state"
                  :items="getStates"
                  label="State"
                  :rules="actionType === 'edit' ? [] : [rules.required, stateCountyRules.uniqueState]"
                  :disabled="actionType === 'edit'"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentCountyName"
                  ref="countyTxt"
                  label="County"
                  :rules="[stateCountyRules.countyRequired, stateCountyRules.uniqueCountyInState]"
                  @keyup.enter="addCounty($event)"
                  @blur="$event.target.value = ''"
                />
                <v-chip-group
                  column
                >
                  <v-chip 
                    v-for="county in currentRecord.counties" 
                    :key="county"
                    close
                    @click:close="removeCounty(county)"
                  >
                    {{ county }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
          <v-btn
            @click="cancelDialogClick"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="formLoading"
            :disabled="!userFormValid"
            @click="handleDialogSave()"
          >
            Save
          </v-btn>
        </v-card-actions>      
    </v-dialog>
  </div>
</template>

<script>
import stateCountiesMixin from '../mixins/stateCounties.mixin';

export default {
  name: 'StateCounties',

  mixins: [stateCountiesMixin]
}
</script>

<style lang="scss">
.v-dialog--active {
  background-color: white;
}
</style>