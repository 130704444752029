<template>
  <v-container id="legal-form-guide">
    <v-row >
      <v-col cols="12" sm="10" lg="10" class="mx-auto">
        <h1 class="mb-5">Divorce Forms</h1>
        <v-stepper v-model="currentStep" non-linea @change="changeStep">
          <v-stepper-header>
            <v-stepper-step step="1" editable :complete="currentStep === 1">
              State
            </v-stepper-step>
            <v-stepper-step step="2" :editable="currentState.length > 0" :complete="currentStep === 2">
              Type
            </v-stepper-step>
            <v-stepper-step step="3" :editable="currentStep > 2" :complete="currentStep === 3">
              Participating
            </v-stepper-step>
            <v-stepper-step step="4" :editable="isEditable" :complete="currentStep === 4">
              Children
            </v-stepper-step>
            <v-stepper-step step="5" :editable="isEditable" :complete="currentStep === 5">
              County
            </v-stepper-step>
            <v-stepper-step step="6" :editable="currentStep > 4 && collaborate === true" :complete="currentStep === 6">
              Tutorial
            </v-stepper-step>
            <v-stepper-step step="7" :editable="currentStep > 4" :complete="currentStep === 7">
              Jurisdiction
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <div style="display:flex;flex-direction:column;align-items:center;">
                <h1 class="mobile-header">State</h1>
                <p>
                  Select the form corresponding to your state
                </p>
                <v-select
                  :items="getStates"
                  :loading="getStates.length === 0"
                  v-model="currentState"
                  item-text="state"
                  return-object
                  label="What state are you from"
                  :autofocus="true"
                  no-data-text="Loading States..."
                  outlined
                  style="height:48px;width:80%;"
                  @change="() => this.currentStep += 1"
                />
                <p style="margin-top:20px;">
                  Select your state and
                  <strong style="color: #3fd0c9; font-size: 24px">
                    Let’s begin!
                  </strong>
                </p>
                <v-img
                  :src="require(`@/assets/images/letsStart.svg`)"
                  height="250"
                  class="mt-10"
                  contain
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div style="display:flex;flex-direction:column;align-items:center;margin-bottom:20px;">
                <h1 class="mobile-header">Type</h1>
                <p>
                  Select the form corresponding to your type
                </p>
                <v-select
                  :items="typesByStateAndForm(currentState)"
                  :loading="types.length === 0"
                  v-model="currentType"
                  item-text="state"
                  return-object
                  label="Type"
                  :autofocus="true"
                  no-data-text="Loading Types..."
                  outlined
                  clearable
                  style="height:48px;width:80%;"
                  @change="typeSelected($event)"
                />
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" style="padding:10px 0;">
              <div style="display:flex;flex-direction:column;align-items:center;">
                <h1 class="mobile-header">Participating</h1>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6">
                      <CostInfo
                        v-if="unilateral"
                        :price="unilateral.price"
                        :show-select-btn="true"
                        :summary="unilateral.summary"
                        :image="unilateral.img"
                        :tab="{ title: unilateral.title, bg: unilateral.bg, fg: unilateral.fg }"
                        :items="unilateral.items"
                        @selected="collaborateClick(false)"
                      />
                    </v-col>
                    <v-col>
                        <CostInfo
                          v-if="collaborative"
                          :price="collaborative.price"
                          :show-select-btn="true"
                          :image="collaborative.img"
                          :summary="collaborative.summary"
                          :tab="{ title: collaborative.title, bg: collaborative.bg, fg: collaborative.fg }"
                          :items="collaborative.items"
                          @selected="collaborateClick(true)"
                        />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-stepper-content>

            <v-stepper-content step="4">
              <div style="display:flex;flex-direction:column;align-items:center;">
                Do you and your spouse have any children together?<br />
                <v-radio-group
                  style="margin-left:20px;"
                  v-model="childrenOptions"
                  :column="false"
                  @change="handleChildrenOptions"
                >
                  <v-radio value="with" label="Yes" style="margin-right: 10px;" />
                  <v-radio value="without" label="No" style="margin-right: 10px;" />
                </v-radio-group>
              </div>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-container v-if="hasCounties">
                <v-row>
                  <v-col cols="12">
                    <h1 class="mobile-header">County</h1>
                    What county do you live in?
                    <v-select
                      v-model="currentCounty"
                      placeholder="Choose a county"
                      outlined
                      :items="countiesByStateAndKids || []"
                      no-data-text="Sorry, no counties found for this form"
                      @change="countySelected"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="6">
              <h1 class="mobile-header">Tutorial</h1>
              <youtube video-id="ZVH6d8h8kMw" ref="tutorialVideo" title="Tutorial" />
              <br />
              <v-btn color="primary" style="margin:20px 0" @click="nextClick">Next</v-btn>
            </v-stepper-content>

            <v-stepper-content step="7">
              <h1 class="mobile-header">Jurisdiction</h1>
              {{ jurisdiction }}<br />
              <v-btn
                style="margin-top:20px;margin-bottom:10px;"
                color="primary"
                @click="redirectToState()"
              >
                I Agree
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import legalFormGuideMixin from '@/mixins/views/legalFormGuide.mixin';

export default {
  name: "LegalFormGuide",

  mixins: [legalFormGuideMixin]
};
</script>

<style lang="scss">
.mobile-header {
  display: none;
}

.v-stepper__wrapper {
  height: fit-content;
}

.v-application .mx-auto {
  margin: 0 !important;
  min-width: 100% !important;
}

.tutorial-video {
  width: 100%;
  max-width:560px;
  height:315px;
}

.v-stepper__step {
  padding:10px;
}

@media (max-width: 600px) {
  .mobile-header {
    margin-top: 10px;
    display: block;
  }

  .tutorial-video {
    height:200px;
  }
}
</style>
