import dayjs from 'dayjs';
import MONTHS from '@/utils/months.util'

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          if(this.ignoreValue(value)) return false
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        numeric: (value) => {
          const numericTest = /^[-,0-9]+$/;

          if (value.match(numericTest)) {
            return true;
          } else {
            return "Must include only numbers.";
          }
        },
        month: (value) => MONTHS.includes(value) || 'Please select a year',
        year: (value) => {
          if(!value || isNaN(value) || value?.length !== 4) return 'Invalid year format'
          const currentYear = new Date().getFullYear()
          const minYear = (currentYear - 100)
          if(value < minYear || value > currentYear) return `Year must be between ${minYear} and ${currentYear}`
          return true
        },
        date: (value) => {
          if(!value || this.ignoreValue(value)) return true
          const pattern = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/
          return pattern.test(value) || 'Invalid date format. Example MM/DD/YYYY'
        },
        dateLength: (value) => {
          if(value?.length !== 10) {
            return "Please enter a proper date value"
          } else {
            return true
          }
        },
        range: (min, max) => (value) => {
          if(+value < +min || +value > +max) {
            return `Please enter a range between ${min} and ${max}`
          } else {
            return true
          }
        },
        phone: (value) => {
          if(this.ignoreValue(value)) return true
          const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
          return pattern.test(value) || 'Invalid phone number. Example: ###-###-####'
        },
        ssn: (value) => {
          if(this.ignoreValue(value)) return true
          const pattern = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/
          return pattern.test(value) || 'Invalid social security number. Example: ###-##-####'
        },
        time: (value) => {
          if(this.ignoreValue(value)) return true
          const pattern = /([0-2]?[0-9]|1[0-2]):([0-5][0-9])/
          return pattern.test(value) || 'Invalid time entry. Example: hh:mm am/pm'
        },
        adult: (value) => {
          if(!value) return true
          const status = dayjs(dayjs()).diff(dayjs(this.safeDate(value)), 'years') >= 18 || 'Sorry, date of birth must be over 18 to file for divorce.'
          return status
        },
        child: (value) => {
          if(!value) return false
          let msg = ''
          const isChild = dayjs(dayjs()).diff(dayjs(this.safeDate(value)), 'years') < 18
          if (!isChild) {
            msg = 'Sorry, date of birth must be under 18 to file for custody.'
          }
          const isBorn = dayjs(dayjs()).diff(dayjs(this.safeDate(value)), 'days') > 0
          if (!isBorn) {
            msg = 'Sorry, date of birth must be greater than today.'
          }
          return (isBorn && isChild) || msg
        },
        lengthZipCode: (value) => {
          const lengthTest = /^.{5}$/;
          const stringValue = value.toString();

          if (stringValue.match(lengthTest)) {
            return true;
          } else {
            return "Please enter a valid 5-digit ZIP code.";
          }
        },
        lengthPhoneNumber: (value) => {
          const lengthTest = /^.{10}$/;

          if (value.replace(/-/g, '').match(lengthTest)) {
            return true;
          } else {
            return "Please enter a valid 10-digit US phone number.";
          }
        },
        password: (v) => {
          const passwordTest =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

          if (v.match(passwordTest)) {
            return true;
          } else {
            return "Password must include at least one lowercase letter, one uppercase letter, one number, and one special character.";
          }
        },
        lengthPassword: (v) => v.length >= 10 || "Min 10 characters",        
      }
    }
  },
  
  methods: {
    ignoreValue(value) {
      if(!value || value.length <= 3) return true
    },

    safeDate(value) {
      return typeof value === 'string' ? value.replace(/nd|th|st|rd/g, '') : ''
    },    
  }
}