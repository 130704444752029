<template>
  <v-card tile id="file-renderer">
    <v-card-title v-if="!hideTitle" class="bg-primary white--text">
      <slot name="card-title">File Renderer</slot>
    </v-card-title>
    <v-divider />
    <v-card-text id="dfr-pdf-container" ref="dfr-pdf-container">
      <v-row justify="space-around">
        <v-btn id="prev" outlined style="width: 110px" @click="onPrevPage">
          <slot name="previous-page">Previous</slot>
        </v-btn>
        <span id="paging-text">
          Page:
          <span id="dfr-page-num">{{ pageNum }}</span> /
          <span id="page_count">{{ totalPageNum }}</span>
        </span>
        <v-btn id="next" outlined style="width: 110px" @click="onNextPage">
          <slot name="next-page">Next</slot>
        </v-btn>
      </v-row>

      <div
        class="canvas-container"
        ref="canvas-container"
        style="position: relative; margin-top: 1.5rem; border: 1px solid gray"
      >
        <slot name="vue-resizable" />
        <canvas :id="`dfr-canvas-${checker}`" ref="dfr-canvas" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import fileRendererMixin from "@/mixins/components/fileRenderer.mixin";
export default {
  name: "FileRenderer",

  mixins: [fileRendererMixin],
}
</script>

<style lang="scss">
#dfr-canvas {
  border: solid black 1px;
}

#file-renderer {
  #paging-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
