import {mapGetters, mapActions} from 'vuex';
import {nanoid} from "nanoid";
import ReorderDocumentsDialog from '@/components/ReorderDocumentsDialog';
import PageCards from "@/components/WorkflowBuilder/Documents/PageCards";
import FileRenderer from "@/components/FileRenderer";
import questionsMixin from "@/mixins/questions.mixin"
import DocDisplayLogic from '@/components/WorkflowBuilder/Documents/DocDisplayLogic.vue';
import pdfToPng from "@/services/pdfToPng";
import pdfMixin from "@/mixins/pdf.mixin";

export default {
  mixins: [pdfMixin,questionsMixin],

  components: {
    PageCards,
    FileRenderer,
    ReorderDocumentsDialog,
    DocDisplayLogic
  },

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    docCombinedPages: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      questionSelection: {},
      showReorderDocsDialog: {
        visible: false
      },
      editDocument: false,
      createDocument: false,
      loadingNewPage: false,
      documentConfirmationDialog: false,

      documentOptions: {
        name: "",
        selectedType: "PDF",
        types: ["PDF"]
      },

      pdfTemplatePageName: {
        dialog: false,
        updatedName: "",
        updatedNameIsUnique: false,
        type: "",
        index: null
      },

      pdfTemplatePageNumber: {
        dialog: false,
        pageSelection: null,
        currentPage: null,
        type: "",
        index: null
      },
    }
  },

  computed: {
    ...mapGetters(['getAcceptedUploadFileTypes']),

    showDocDisplayLogic() {
      return this.form && this.currentDocIndex > -1 && this.form?.fields?.length > 0
    },

    currentDocIndex: {
      get() {
        return this.$store.state.formBuilderDocIndex;
      },
      set(value) {
        this.$store.dispatch("setFormBuilderDocIndex", value);
      }
    },   

    getActiveFormDocs() {
      const docs = this.form?.docs || [];
      const formattedDocs = [];

      // Close edit/create document panel
      this.editDocument = false;
      this.createDocument = false;

      docs.sort((a,b) => a.order - b.order).map((doc, i) => {
        formattedDocs.push({
          id: i,
          title: doc.name ?? `Document ${doc.uid}`
        });
      });

      return formattedDocs;
    },

    hasExistingPages() {
      if (this.form?.docs?.length > parseInt(this.currentDocIndex)) {
        return this.form.docs[this.currentDocIndex]?.existingPages?.length > 0
      } else {
        return false;
      }
    },

    hasCurrentSessionPages() {
      if (this.form?.docs?.length > parseInt(this.currentDocIndex)) {
        return this.form.docs[this.currentDocIndex]?.currentSessionPages?.length > 0
      } else {
        return false;
      }
    },

    getDocTemplate() {
      return this.form?.docs && this.currentDocIndex >= 0
        ? this.form.docs[this.currentDocIndex]
        : {
          dbRefs: [],
          existingPages: [],
          currentSessionPages: [],
          combinedPages: [],
          pagesToDelete: []
        };
    },

    pdfTemplatePagesItems() {
      let currentPages = this.docCombinedPages.slice();

      currentPages.map(cp => {
        cp.moveToTitle = `Page ${cp.page} | ${cp.name}`;
      });

      currentPages.unshift({moveToTitle: "START", page: 0});

      currentPages.sort((a,b) => a.page - b.page);

      return currentPages;
    },
  },

  mounted() {
    this.setDisplayLogic()
  },

  methods: {
    ...mapActions({
      toggleLoadingOverlayStatus: 'toggleLoadingOverlayStatus'
    }),

    ...mapActions("workflow", ["setWorkflowBuilderCurrentRule", "setWorkflowBuilderCurrentAction"]),

    setDisplayLogic() {
      if(this.form.docs[this.currentDocIndex].hasOwnProperty('displayLogic') === false) {
        this.form.docs[this.currentDocIndex]['displayLogic'] = {
          questions: [],
          conditional: '',
          operator: ''
        }
      }
    },

    // TODO: this is very hacky and we need a way to handle when there are no rules or acitons selected
    dissmisModal() {
      this.declinePdfTemplateName();
    },

    documentChange() {
      this.setDisplayLogic()
      this.setWorkflowBuilderCurrentRule(0)
      this.setWorkflowBuilderCurrentAction(0)
    },

    /*
      DOCUMENTS & PAGES
    */

    async addNewDocument() {
      const docId = nanoid(12);
      let docName = `Document ${docId}`;
      if (this.documentOptions.name.length > 0)
        docName = this.documentOptions.name;

      await this.$store
        .dispatch("addDocument", {
          uid: docId,
          type: "pdf", // When we support spreadsheets, that will be an option here
          name: docName,
          combinedPages: [],
          existingPages: [],
          currentSessionPages: [],
          pagesToDelete: [],
          rules: [this.createBlankRule()]
        })
        // Set currentDocIndex to the newly added document
        this.currentDocIndex = this.form.docs.length - 1;
        this.documentOptions.name = "";
        this.createDocument = false;
    },  

    createBlankRule() {
      return {
        uid: nanoid(12),
        name: "",
        type: "",
        displayLogicQuestions: [],
        order: 1,
        condition: null,
        operator: null,
        questions: [
          {
            uid: null,
            title: null,
            type: "",
            selection: {},
            options: []
          }
        ],
        actions: []
      };
    },

    async editCurrentDocument() {
      if (this.documentOptions.name.length === 0) {
        // Name is not updated if user doesn't provide new name
        this.editDocument = false;
      } else {
        // Name is updated
        await this.$store.dispatch("editDocument", {
          name: this.documentOptions.name
        })
        this.documentOptions.name = "";
        this.editDocument = false;
      }
    },

    deleteCurrentDocument() {
      if (this.form.docs.length > 1) {
        this.$store.dispatch("deleteDocument", {docId: this.getDocTemplate.uid});
        this.currentDocIndex = 0;
        this.documentConfirmationDialog = false;
      }
    },

    activateDocPageMoveDialog(index, type) {
      this.$store
          .dispatch("sortDocumentPages", {
            docId: this.getDocTemplate.uid
          })
          .then(() => {
            this.pdfTemplatePageNumber.type = type;
            this.pdfTemplatePageNumber.index = index;
            this.pdfTemplatePageNumber.currentPage =
                type === "existing"
                    ? this.getDocTemplate.existingPages[index].page
                    : this.getDocTemplate.currentSessionPages[index].page;
            this.pdfTemplatePageNumber.pageSelection = null;
            this.pdfTemplatePageNumber.dialog = true;
          });
    },

    activatePageNameDialog(index, type) {
      this.pdfTemplatePageName.type = type;
      this.pdfTemplatePageName.index = index;

      if (type === "existing-pages") {
        this.pdfTemplatePageName.updatedName = this.getDocTemplate.existingPages[
            index
            ].name;
        this.pdfTemplatePageName.updatedNameIsUnique = this.getDocTemplate.existingPages[
            index
            ].isNameUnique;
      } else if (type === "current-session-pages") {
        this.pdfTemplatePageName.updatedName = this.getDocTemplate.currentSessionPages[
            index
            ].name;
        this.pdfTemplatePageName.updatedNameIsUnique = this.getDocTemplate.currentSessionPages[
            index
            ].isNameUnique;
      }

      this.pdfTemplatePageName.updatedName = this.pdfTemplatePageName.updatedName.toUpperCase();
      this.pdfTemplatePageName.updatedNameIsUnique = this.isPageNameUnique(
          this.pdfTemplatePageName.updatedName
      );

      this.pdfTemplatePageName.dialog = true;
    },

    choosePageUpload() {
      // This function just clicks the hidden file-input component
      document.getElementById("add-next-page").click();
    },

    async addNewPage(element = undefined) {
      this.loadingNewPage = true;
      if (element !== undefined) {
        // Handle page with template image
        this.toggleLoadingOverlayStatus(true);
        let reader = new FileReader();
        reader.onloadend = async () => {
          let fileResult = reader.result;
          if (element.type === "application/pdf") {
            const pdfImage = await pdfToPng.convert(element);
            fileResult = pdfImage.data;
          }

          if(Array.isArray(fileResult)) {
            const toConfigure = []
            for(let f = 0; f < fileResult.length; f++) {
              const data = fileResult[f]
              const fileData = element.name.split('.')
              const name = fileData[0] + (f + 1) + "." + fileData[1]
              toConfigure.push(this.configureNewPage({ data,name }))
            }
            await Promise.all(toConfigure)
          } else {
            await this.configureNewPage({
              data: fileResult,
              name: element.name
            });            
          }

          this.toggleLoadingOverlayStatus(false);
          this.loadingNewPage = false;
        };
        reader.readAsDataURL(element);
      } else {
        // Handle blank page
        await this.configureNewPage({data: undefined, name: "NEW BLANK PAGE"});
        this.toggleLoadingOverlayStatus(false);
        this.loadingNewPage = false;
      }
    },

    async configureNewPage(file = {data: undefined, name: "NEW PAGE"}) {
      // Get page name
      let fileName = "";
      if (file === undefined || file.data === undefined) {
        fileName = "NEW BLANK PAGE";
      } else {
        const regexMatch = file.name.match(/.+(?=\.)/);
        if (regexMatch[0]) fileName = regexMatch[0].toUpperCase();
      }

      await this.$store.dispatch("sortDocumentPages", {
        docId: this.currentDocIndex
      });

      // Calculate page number
      let pageNumber = 1;
      if (this.hasExistingPages) {
        pageNumber += this.getDocTemplate.existingPages.length;
      }
      if (this.hasCurrentSessionPages) {
        pageNumber += this.getDocTemplate.currentSessionPages.length;
      }

      // Check that name of file is unique and change it if not, so it will not overwrite page that already exists in storage
      let isNameUnique = this.isPageNameUnique(fileName, "new-page");

      if (!isNameUnique) {
        fileName = this.generateUniqueName(fileName);
      }

      // Create UID for new page
      const uid = nanoid(12);

      this.$store
          .dispatch("addDocumentPage", {
            docIndex: this.currentDocIndex,
            newPage: {
              uid: uid,
              page: pageNumber,
              name: fileName,
              isNameUnique: isNameUnique,
              file: file.data
            }
          })
          .then(() => {
            if (!isNameUnique) {
              this.activatePageNameDialog(
                  this.getDocTemplate.currentSessionPages.length - 1,
                  "current-session-pages"
              );
            }
          });
    },

    removeExistingPage(index) {
      this.$store.dispatch("deleteDocumentPage", {
        docId: this.getDocTemplate.uid,
        type: "existing",
        pageIndex: index,
      });
    },

    removeCurrentSessionPage(index) {
      this.$store.dispatch("deleteDocumentPage", {
        docId: this.getDocTemplate.uid,
        type: "current-session",
        pageIndex: index,
      });
    },

    updateExistingPages(pages) {
      this.$store.dispatch("updateDocumentPageNumbers", {
        type: "existing",
        currentDocIndex: this.currentDocIndex,
        pageIndex: index
      });

      this.pdfTemplate.existingPages = pages;
      this.form.docs[this.currentDocIndex].existingPages = pages;
    },

    updateCurrentSessionPages(pages) {
      this.pdfTemplate.existingPages = pages;
      this.form.docs[this.currentDocIndex].existingPages = pages;
    },

    isPageNameUnique(name, type = "existing-page") {
      let duplicateCount = 0;

      this.docCombinedPages.forEach(page => {
        if (page.name.trim().toUpperCase() === name.trim()) duplicateCount++;
      });

      // This runs before the page is added and combinedPages is updated, so if there are any duplicates, the page name
      // is not unique
      return !duplicateCount;
    },

    generateUniqueName(name, counter = 0) {
      name = counter ? `${name} ${counter}` : name;
      let isUnique = this.isPageNameUnique(name);

      if(!isUnique) {
        return this.generateUniqueName(name, ++counter);
      }

      return name;
    },

    checkPdfTemplateName() {
      // check that name of file is unique and update pdfTemplatePageName.updatedNameIsUnique flag
      this.pdfTemplatePageName.updatedName = this.pdfTemplatePageName.updatedName.toUpperCase();

      this.pdfTemplatePageName.updatedNameIsUnique = this.isPageNameUnique(
          this.pdfTemplatePageName.updatedName,
          "existing-page"
      );
    },

    acceptPdfTemplateName() {
      // check that name of file is unique and change it if not, so it will not overwrite page that already exists in storage
      this.checkPdfTemplateName();

      const isNameUnique = this.pdfTemplatePageName.updatedNameIsUnique;
      const index = this.pdfTemplatePageName.index;
      const type = this.pdfTemplatePageName.type;
      const updatedName = this.pdfTemplatePageName.updatedName;

      if (isNameUnique) {
        const payload = {
          pageIndex: index,
          type: type,
          updates: {
            name: updatedName,
            isNameUnique: isNameUnique
          }
        };
        this.$store.dispatch("editDocumentPage", payload);

        // Reset renaming dialog
        this.pdfTemplatePageName = {
          dialog: false,
          updatedName: "",
          updatedNameIsUnique: false,
          type: "",
          index: null
        };
      }
    },

    declinePdfTemplateName() {
      if (!this.pdfTemplatePageName.updatedNameIsUnique) {
        let index = this.form.docs[this.currentDocIndex].existingPages.findIndex(item => {
          if(item) {
            item.name === this.pdfTemplatePageName.name;
          }
        });

        if (!index) {
            index = this.form.docs[this.currentDocIndex].currentSessionPages.findIndex(item => {
              if(item) {
                item.name === this.pdfTemplatePageName.name;
              }
            })
        }

        if (this.pdfTemplatePageName.type === 'current-session-pages') {
          this.removeCurrentSessionPage(index);
        } else {
          this.removeExistingPage(index);
        }
      }

      this.pdfTemplatePageName = {
        dialog: false,
        updatedName: "",
        updatedNameIsUnique: false,
        type: "",
        index: null
      };
    },

    acceptDocPageMovement() {
      this.$store
          .dispatch("moveDocumentPage", {
            docId: this.getDocTemplate.uid,
            type: this.pdfTemplatePageNumber.type,
            index: this.pdfTemplatePageNumber.index,
            pageFrom: this.pdfTemplatePageNumber.currentPage,
            pageTo: this.pdfTemplatePageNumber.pageSelection
          })
          .then(() => {
            // Clear move page dialog
            this.pdfTemplatePageNumber = {
              dialog: false,
              pageSelection: null,
              currentPage: null,
              type: "",
              index: null
            };
          });
    },

    declineDocPageMovement() {
      this.pdfTemplatePageNumber = {
        dialog: false,
        pageSelection: null,
        currentPage: null,
        type: "",
        index: null
      };
    },
  }
}