import {v4 as uuidv4} from 'uuid'
import { mapGetters, mapActions } from 'vuex'
import rulesMixin from "@/mixins/rules.mixin";
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

export default {
  mixins: [adminCheckerMixin, rulesMixin],

  data() {
    return {
      dataLoading: false,
      formLoading: false,
      deleteLoading: 0,
      dialog: false,
      actionType: '',
      userFormValid: false,
      actionTitle: '',
      headers: [
        {
          text: 'Title',
          value: 'title',
          sortable: true
        },
        {
          text: 'States',
          value: 'states',
          sortable: false
        },
        {
          text: 'Active?',
          value: 'active',
          sortable: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      currentWfType: {
        id: 0,
        states: [],
        statesToRemove: []
      }
    }
  },

  watch: {
    ['currentWfType.states'] (newValue, oldValue) {
      if(this.currentWfType.id !== 0 && newValue.length < oldValue.length) {
        this.currentWfType['statesToRemove'].push(oldValue.find(o => !newValue.includes(o)))
      }
    }
  },

  mounted() {
    this.setDefaultWfType()
    if(this.types.length === 0) {
      this.dataLoading = true
      this.setTypesAction().then(() => {
        this.dataLoading = false
      })
    }
  },

  computed: {
    ...mapGetters('workflowTypes', ['types']),

    ...mapGetters(['getStates','getFirebaseConfig', 'getForms'])
  },

  methods: {
    ...mapActions('workflowTypes', [
      'setTypesAction', 
      'updateDefaultAction',
      'addTypeAction',
      'updateTypeAction',
      'deleteTypeAction'
    ]),

    ...mapActions('workflowPublished', ['deletePublishedWorkflow','getRawPublishedByStateAndType']),

    ...mapActions(['updateForms']),

    ...mapActions('workflow', ['updateRawForm']),

    ...mapActions("confirmation", ["confirm"]),

    handleDefaultChange() {
      const title = this.currentWfType.title
      this.updateDefaultAction({ title })
    },

    setDefaultWfType() {
      this.currentWfType = {
        id: 0,
        title: '',
        states: [],
        active: 'Yes',
        statesToRemove: []
      }
    },

    cancelDialogClick() {
      this.dialog = false
    },

    handleDialogSave() {
      if(this.actionType === 'add') {
        this.typeAddClick()
      } else {
        this.typeUpdateClick()
      }
    },
    
    typeCreateDialogClick() {
      this.dialog = true
      this.actionType = 'add'
      this.actionTitle = 'Create Workflow Type'
      this.setDefaultWfType()
    },

    async typeAddClick() {
      this.formLoading = true
      const wfType = {...this.currentWfType, id: uuidv4()}
      delete wfType.default
      delete wfType.statesToRemove
      await this.addTypeAction(wfType)
      this.resetForm()
    },

    typeUpdateDialogClick(wfType) {
      this.actionType = 'edit'
      this.dialog = true
      this.actionTitle = `Update ${wfType.title}`
      this.currentWfType = {...wfType, statesToRemove: []}
    },

    async typeUpdateClick() {
      this.formLoading = true
      const wfType = {...this.currentWfType}

      const formsToUpdate = []
      for(let w = 0; w < wfType.statesToRemove.length; w++) {
        const state = wfType.statesToRemove[w]
        const publishWorkflowsToBeDeleted = await this.getRawPublishedByStateAndType({
          state,
          type: wfType.title
        })
        for(const publishedWorkflow of publishWorkflowsToBeDeleted) {
          formsToUpdate.push(this.deletePublishedWorkflow({ id: publishedWorkflow.id, collection: 'workflows-published' }))
          formsToUpdate.push(this.updateRawForm({
            formId: publishedWorkflow.parentId,
            data: {
              status: ''
            }
          }))
          if(this.getForms?.length > 0) {
            const currentForm = this.getForms.find(form => form.id === publishedWorkflow.parentId)
            if(currentForm) {
              currentForm.status = ''
            }
          }
        }
      }
      await Promise.all(formsToUpdate)
      delete wfType.statesToRemove
      delete wfType.default
      this.updateTypeAction(wfType).then(() => {
        this.resetForm()
      })
    },

    resetForm() {
      this.dialog = false
      this.formLoading = false
      this.actionType = ''
      this.actionTitle = ''
      this.setDefaultWfType()
    },

    typeDeleteClick({ id, title }) {
      this.confirm({
        title: `Are you sure you want to delete ${title}?`,
        accepted: () => {
          this.deleteLoading = id
          this.deleteTypeAction(id).then(() => {
            this.deleteLoading = 0
          })          
        }
      })
    }
  }  
}