<template>
    <div style="margin-top:40px;">
        <h3 style="margin-left:20px;">COUPONS</h3>
        <v-btn @click="codeCreateClick">Add</v-btn>
        <div style="margin: 0 40px;">
            <v-data-table
                :headers="headers"
                :items="coupons"
                :loading="loading"
                loading-text="Loading ... Please wait"
                :items-per-page="50"
            >
                <template v-slot:item.campaign="{ item }">
                    <v-icon v-if="item.campaign" title="Campaign">
                        mdi-star
                    </v-icon>
                </template>
                <template v-slot:item.amount="{ item }">
                    {{ item.amount }}{{ showPercent(item.type) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        icon
                        color="primary"
                        :loading="(item.code === updateCode)"
                        @click="codeUpdateClick(item.original)"
                    >
                        <v-icon
                            color="primary"
                            title="Update Coupon"
                            small
                        >
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="primary"
                        :loading="(item.code === deleteCode)"
                        @click="codeDeleteClick(item.code)"
                    >
                        <v-icon
                            color="primary"
                            title="Delete Coupon"
                            small
                        >
                            mdi-delete-forever
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="300px"
            :retain-focus="false"
        >
            <v-card>
                <v-card-title style="position:relative;">
                    {{ actionTitle }}
                    <v-btn
                        style="margin-left:1px;position:absolute;top:0;right:0;"
                        icon
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <EditCoupon
                        v-if="dialog"
                        :operation="operation"
                        @saved="handleSaved"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import couponManageMixin from '@/mixins/couponManage.mixin';

export default {
    name: "CouponManager",

    mixins: [couponManageMixin],
}
</script>
