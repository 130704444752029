<template>
    <div>
        <v-form v-model="userFormValid">
            <v-text-field
                @input="() => (coupon.code = coupon.code.toUpperCase())"
                v-model="coupon.code"
                label="Code"
                :rules="[rules.required]"
                :disabled="operation === 'edit'"
            />
            <v-select
                v-model="coupon.campaign"
                label="Campaign"
                :items="campaignItems"
                @change="handleCampaignChange" />
            <v-text-field
                v-model="coupon.amount"
                label="Amount"
                type="number"
                min="1"
                max="100"
                :rules="[rules.required,rules.range(1,100)]"
                :suffix="showPercent" />
            <v-text-field
                v-model="coupon.limit"
                label="Limit"
                type="number" />
            <v-text-field
                v-model="coupon.startDate"
                label="Start Date"
                placeholder="MM/DD/YYYY"
                :rules="[rules.required,rules.date]"
                v-mask="'##/##/####'" />
            <v-text-field
                v-model="coupon.endDate"
                label="End Date"
                placeholder="MM/DD/YYYY"
                :rules="[rules.required,rules.date]"
                v-mask="'##/##/####'" />
            <v-btn
                :loading="loading"
                :disabled="!userFormValid"
                @click="handleCouponCode"
            >
                Save
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import couponEditMixin from '@/mixins/couponEdit.mixin';

export default {
    name: "EditCoupon",

    mixins: [couponEditMixin]
}
</script>
