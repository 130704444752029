import { mapGetters, mapActions } from 'vuex'
import EditCoupon from '@/views/coupons/edit'
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
const { getDateFormat } = require('@/utils/getDateFormat.util')

export default {
  mixins: [adminCheckerMixin],

  components: {
      EditCoupon,
  },

  data() {
      return {
          actionTitle: '',
          dialog: false,
          updateCode: '',
          deleteCode: '',
          loading: true,
          operation: 'add',
          headers: [
              {
                  text: '',
                  value: 'campaign',
                  sortable: true,
              },
              {
                  text: 'Amount',
                  value: 'amount',
                  sortable: true,
              },
              {
                  text: 'Code',
                  value: 'code',
                  sortable: true,
              },
              {
                  text: 'Count',
                  value: 'count',
                  sortable: true,
              },
              {
                  text: 'Limit',
                  value: 'limit',
                  sortable: true,
              },
              {
                  text: 'Start Date',
                  value: 'startDate',
                  sortable: true,
              },
              {
                  text: 'End Date',
                  value: 'endDate',
                  sortable: true,
              },
              {
                  text: 'Actions',
                  value: 'actions',
                  sortable: false,
              },
          ]
      }
  },

  computed: {
      ...mapGetters("coupons", ["coupons", "coupon"]),

      showPercent() {
          return (type) => {
              return type === 'percent' ? '%' : ''
          }
      }
  },

  methods: {
      ...mapActions("confirmation", ["confirm"]),

      ...mapActions("coupons", [
          "deleteCoupon",
          "getCoupons",
          "setCurrentCoupon",
      ]),

      handleSaved() {
          this.getCoupons()
          this.dialog = false
          this.operation = 'add'
      },

      codeCreateClick() {
          this.operation = 'add'
          this.setCurrentCoupon({
              amount: 1,
              code: '',
              count: 0,
              campaign: false,
              limit: 0,
              payall: 'both',
              type: 'percent',
              startDate: getDateFormat(new Date(), 'MM/DD/YYYY'),
              endDate: ''
          })
          this.dialog = true
          this.actionTitle = 'Create Coupon Code'
      },

      codeUpdateClick(coupon) {
          this.operation = 'edit'
          this.setCurrentCoupon({...coupon})
          this.dialog = true
          this.actionTitle = `Update ${coupon.code}`
      },

      async codeDeleteClick(code) {
          this.confirm({
              title: `Are you sure you want to delete ${code}`,
              accepted: async () => {
                  this.deleteCode = code
                  await this.deleteCoupon(code)
                  this.deleteCode = ''
              }
          })
      }
  },

  async mounted() {
      await this.getCoupons()
      this.loading = false
  }  
}