const defaultState = () => ({
  types: [],
  default: {}
})

export const workflowTypes = {
  namespaced: true,

  state: defaultState(),

  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async setTypesAction({ commit, rootState }) {
      const db = rootState.firebaseConfig.db
      const data = (await db.collection('workflow-types').get()).docs.map(t => ({ id: t.id, ...t.data() }))
      const defaultType = data.find(d => d.id === 'default')
      const types = data.filter(d => d.id !== 'default')
      commit('SET_DEFAULT', defaultType)
      commit('SET_TYPES', types)
    },

    async updateDefaultAction({ commit, rootState }, payload) {
      const db = rootState.firebaseConfig.db
      await db.collection('workflow-types').doc('default').set(payload, { merge: true })
      commit('SET_DEFAULT', payload)
    },

    async addTypeAction({ commit, rootState, state }, payload) {
      const db = rootState.firebaseConfig.db
      const types = [...state.types, payload]
      commit('SET_TYPES', types)
      await db.collection('workflow-types').doc(payload.id).set(payload, { merge: true })
      return
    },

    async updateTypeAction({ rootState, state }, payload) {
      const db = rootState.firebaseConfig.db
      const typeToUpdate = state.types.find(t => t.id === payload.id)
      typeToUpdate.states = [...payload.states]
      await db.collection('workflow-types').doc(typeToUpdate.id).set(payload, { merge: true })
      return
    },

    async deleteTypeAction({ commit, rootState, state }, id) {
      const db = rootState.firebaseConfig.db
      const types = [...state.types.filter(t => t.id !== id)]
      commit('SET_TYPES', types)
      await db.collection('workflow-types').doc(id).delete()
      return
    }
  },

  getters: {
    types: (state) => state.types.map(t => ({...t, default: t.title === state.default.title ? 'Yes' : 'No' })),
    typeByState: (state) => (stateName) => {
      if(stateName.length === 0) return []
      return state.types.filter(t => t.states.find(s => s === stateName)).map(t => t.title)
    },
    default: (state) => state.default
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },

    SET_DEFAULT(state, payload) {
      state.default = payload
    },

    SET_TYPES(state, payload) {
      state.types = payload
    }
  }
}