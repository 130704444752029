import { mapGetters, mapActions } from 'vuex';
import rulesMixin from "@/mixins/rules.mixin";

export default {
  mixins: [rulesMixin],

  data() {
    return {
      currentCountyName: '',
      dataLoading: false,
      dialog: false,
      formLoading: false,
      deleteLoading: 0,
      actionType: '',
      actionTitle: '',
      userFormValid: false,
      currentRecord: null,
      stateCountyRules: {
        countyRequired: () => {
          return this.currentRecord.counties.length > 0 || 'Sorry, atleast one county is required'
        },
        uniqueState: (value) => this.states.filter(s => s.state === value).length === 0 || 'Sorry, state has already been used',
        uniqueCountyInState: (value) => this.currentRecord.counties.length > 0 && !this.currentRecord.counties.includes(value) || 'Sorry, county already used for this state'
      },
      headers: [
        {
          text: 'State',
          value: 'state',
          sortable: true
        },
        {
          text: 'Counties',
          value: 'counties',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ]
    }
  },

  mounted() {
    if(Object.keys(this.stateInfo).length === 0) {
      this.dataLoading = true
      this.setCountiesByState().then(() => {
        this.dataLoading = false
      })
    }
  },

  computed: {
    ...mapGetters('counties', ['stateInfo']),

    ...mapGetters(['getStates']),

    states() {
      const stateData = Object.entries(this.stateInfo).map(([key,value]) => ({
        state: key,
        counties: value.counties
      }))
      return stateData
    },

    formatCounties() {
      return (counties) => {
        return counties ? counties.join(', ') : ''
      }
    }
  },

  methods: {
    ...mapActions('counties', [
      'setCountiesByState', 
      'deleteByState',
      'updateCountyByState'
    ]),

    ...mapActions("confirmation", ["confirm"]),

    addCounty(event) {
      if(event?.target?.value) {
        this.currentRecord.counties = [...this.currentRecord.counties, event.target.value]
        this.currentCountyName = ''
      }
    },

    stateCountyCreateClick() {
      this.currentRecord = {
        state: '',
        counties: []
      }
      this.actionTitle = 'Create State Counties'
      this.actionType = 'add'
      this.dialog = true
    },

    stateCountyUpdateClick(stateData) {
      this.currentRecord = stateData
      this.actionTitle = 'Update State Counties'
      this.actionType = 'edit'
      this.dialog = true
    },

    stateCountyDeleteClick(stateName) {
      this.confirm({
        title: `Are you sure you want to delete this ${stateName} and it's counties?`,
        accepted: () => {
          this.deleteLoading = stateName
          this.deleteByState(stateName).then(() => {
            this.deleteLoading = 0
          })
        }
      })
    },

    removeCounty(county) {
      this.confirm({
        title: `Are you sure you want to delete this county?`,
        accepted: () => {
          this.currentRecord.counties = [...this.currentRecord.counties.filter(c => c !== county)]
          this.$refs.countyTxt.validate()
        }
      })
    },

    cancelDialogClick() {
      this.actionType = ''
      this.currentCountyName = ''
      this.dialog = false
    },

    handleDialogSave() {
      this.formLoading = true
      this.updateCountyByState(this.currentRecord).finally(() => {
        this.formLoading = false
        this.dialog = false
        this.actionType = ''
        this.currentCountyName = ''
      })
    }
  }
}