<template>
    <div class="admin-container">
        <div v-for="category in Object.keys(categories)" :key="category">
            <header style="
                font-weight: bold;
                text-align: left;
                margin-left: 40px;
                font-size: 24px;
                margin-top: 20px;
            ">
                {{ category }}
            </header>
            <v-container class="container-category">
                <v-row>
                    <v-col v-for="item in categories[category]" cols="12" xl="2" lg="3" md="4" sm="10" :key="item.title" style="padding:0px;cursor:pointer;border:1px solid silver;margin:10px;width:fit-content;">
                        <v-btn
                            text
                            style="display:block;width:100%;height:100%;padding:10px;justify-content:flex-start;"
                            @click="redirectTo(item.name)"
                        >
                            <v-icon
                                x-large
                            >
                                {{  item.icon }}
                            </v-icon>
                            <div style="margin-left:10px;">
                                {{  item.title }}
                            </div>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import adminMixin from '../mixins/admin.mixin';

export default {
    name: 'Admin',

    mixins: [adminMixin]
}
</script>

<style lang="scss">
.admin-container {
    margin: 40px;

    .container-category {
        margin-left:40px;
        margin-bottom:10px;
    }
}

@media(max-width: 600px) {
    .admin-container {
        margin: 0px;

        .container-category {
            margin-left:0px;
            margin-right:100px;
        }
    }
}
</style>
