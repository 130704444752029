<template>
  <div style="margin-top:40px;">
    <h3 style="margin-left: 20px;">FIREBASE USERS</h3>
    <div style="margin: 0 40px;">
      <v-data-table
        fixed-header
        :headers="headers"
        height="500px"
        :items="userProfiles"
        :search="search"
        :loading="loading"
        sort-by="full_name"
        loading-text="Loading ... Please wait"
        :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25,50,100,-1]
            }"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search User"
            class="ma-4 mt-10"
            rounded
            outlined
            prepend-icon="search"
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            color="error"
            :loading="deleteUserId === item.email"
            @click="removeUser(item)"
          >
            <v-icon
              color="error"
              title="Delete User"
              small
            >
              mdi-account-remove
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import adminCheckerMixin from '@/mixins/adminChecker.mixin'
import dateSort from '@/utils/dateSort.util'

export default {
  name: "Users",

  mixins: [adminCheckerMixin],

  data() {
    return {
      loading: true,
      deleteUserId: '',
      search: '',
      headers: [
      {
          text: 'Last Name',
          value: 'last_name',
          sortable: true,
        },
        {
          text: 'First Name',
          value: 'first_name',
          sortable: true,
        },
        {
          text: 'Verified',
          value: 'verified'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Is Petitioner?',
          value: 'is_petitioner',
          sortable: true,
        },
        {
          text: 'Created On',
          value: 'created_at',
          sortable: true,
          sort: dateSort
        },
        {
          text: 'Login',
          value: 'last_sign_in',
          sortable: true,
          sort: dateSort
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        }
      ]
    }
  },

  async mounted() {
    this.loading = false;
  },

  computed: {
    ...mapGetters("users", ["userProfiles"]),
  },

  methods: {
    ...mapActions("users", ["deleteUser"]),

    ...mapActions("confirmation", ["confirm"]),

    removeUser(item) {
      this.confirm({
        title: 'Are you sure you want to delete user ' + item.full_name + '?',
        accepted: () => {
          this.deleteUserId = item.email
          this.deleteUser(item?.email?.toLowerCase()).then(() => {
            this.deleteUserId = ''
          })
        }
      })
    },
  }
}
</script>
