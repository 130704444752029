import { mapActions, mapGetters } from "vuex";
import sendEmailUtil from '@/utils/send-email.util'
import EmailTemplate from '@/components/EmailTemplate/EmailTemplate'

export default {
  components: {
    EmailTemplate,
  },

  props: {
    shareFormMetadata: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      message: "<b>Hello World</b>",
    };
  },

  computed: {
    ...mapGetters(["getStates"]),

    ...mapGetters("workflowPublished", ["getPublishedWorkflowById","getPublishedWorkflowByState"]),

    stakeHolders() {
      return this.shareFormMetadata.stakeholders;
    },
  },

  mounted() {
    this.setPublishedWorkflows();
  },

  methods: {
    ...mapActions([
      "updateFormAccessAndStakeHolders",
      "toggleLoadingOverlayStatus",
    ]),

    ...mapActions("workflow", ["publishWorkflow"]),

    ...mapActions("workflowPublished", ["setPublishedWorkflows"]),

    ...mapActions("confirmation", ["confirm"]),

    deleteStakeHolder(email) {
      this.confirm({
        title: 'Are you sure you want to remove this stake holder?',
        accepted: () => {
          const index = this.shareFormMetadata.stakeholders.findIndex(
            (s) => s.email === email
          );

          if (index >= 0) {
            this.shareFormMetadata.stakeholders.splice(index, 1);
            this.$forceUpdate();
          }
        }
      })
    },

    async sendEmailToStakeHolders() {
      const baseUrl = `${location.protocol}//${location.host}`;
      this.stakeHolders.forEach((sh) => {
        sendEmailUtil(baseUrl, sh, 'stakeholder', 'Divorce Form Approval')
          .then((status) => {
            this.$logger.info(`Email sent to ${sh.email} with as stakeholder with status of: ${status}`);
            this.toggleLoadingOverlayStatus(false);
          })
          .catch(() => {
            this.toggleLoadingOverlayStatus(false);
          });
      });
    },

    async sendPublishedForm() {
      let stateFormFound = this.getPublishedWorkflowById(this.shareFormMetadata);

      if(!stateFormFound) {
        stateFormFound = this.getPublishedWorkflowByState(this.shareFormMetadata);
      }

      if (stateFormFound && this.stakeHolders.length === 0) {
        const msg = `The state of ${this.shareFormMetadata.state} already has a divorce form of type ${this.shareFormMetadata.type} and children options of ${this.shareFormMetadata.childrenOptions}.\n\nAre you sure you want to proceed?`;
        this.confirm({
          title: msg,
          accepted: async () => {
            await this.proceedToSendPublishedForm()
          }
        })
      } else {
        await this.proceedToSendPublishedForm()
      }
    },

    async proceedToSendPublishedForm() {
      this.toggleLoadingOverlayStatus(true);
      if (this.stakeHolders.length > 0) {
        this.sendEmailToStakeHolders();
        this.stakeHolders.forEach((sh) => {
          sh.status = "Pending";
        });
      } else {
        const { id, state, title } = this.shareFormMetadata;
        await this.publishWorkflow({ id, state, title });
        this.toggleLoadingOverlayStatus(false);
      }

      this.shareFormMetadata.publishDialog = false;
      this.shareFormMetadata.status = this.stakeHolders.length > 0 ? "Pending" : "Approved";
      this.shareFormMetadata.statusDateStamp = Date.now()
      const { id, stakeholders, status, statusDateStamp, state } = this.shareFormMetadata;
      this.updateFormAccessAndStakeHolders({
        id,
        stakeholders,
        status,
        statusDateStamp,
        state,
      });
      this.$emit('published')
    }
  },
}