import petitionerSteps from '../constants/petitionerSteps'
import respondentSteps from '../constants/respondentSteps';
import { getDateFormat } from '@/utils/getDateFormat.util'
import $logger from '@/utils/logger.util'
import dayjs from 'dayjs'

const defaultState = () => ({
  statistics: [],
})

const fixDateFormat = (user, columnName) => {
  return user.hasOwnProperty(columnName) ? getDateFormat(user[columnName], 'MM/DD/YYYY') : getDateFormat('07/01/2023', 'MM/DD/YYYY')
}

export const statisticsLogic = {
  namespaced: true,
  state: defaultState(),
  getters: {
    getStatistics: state => state.statistics,
  },
  mutations: {
    SET_STATISTICS(state, payload) {
      state.statistics = payload;
    },

    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },

    async getUserStatistics({commit, rootState, rootGetters}) {
      let formated = [];
      const db = rootState.firebaseConfig.db
      const users = await db.collection('users').where('role', '==', 'user-basic').get();
      await Promise.all(users.docs.map(async (item) => {
        let difference = new Date(item.data().last_sign_in).getTime() - new Date().getTime();
        let user = item.data();
        user.totalDays = Math.ceil(difference / (1000 * 3600 * 24));
        user['signed'] = ''
        user['completed'] = ''
        user['spouse_email'] = 'Unregistered'
        user['last_sign_in'] = fixDateFormat(user, 'last_sign_in')
        user['created_at'] = fixDateFormat(user, 'created_at')
        user['userType'] = user.is_petitioner ? 'Petitioner' : 'Respondent'
        const dbProfileVerified = rootGetters['users/dbProfiles'].findIndex(p => p.emailVerified && p.email === user.email) > -1 ? 'Yes' : 'No'
        if(dbProfileVerified === 'No') {
          user['verified'] =  dayjs(user.last_sign_in).diff(user.created_at, 'seconds') > 0 ? 'Yes' : 'No'
        } else {
          user['verified'] = dbProfileVerified
        }
        if (user?.forms?.[0]?.workflowsUserId) {
          const formData = (await db.collection('workflows-user').doc(user.forms[0].workflowsUserId).get()).data()
          if(formData) {
            user['workflow'] = formData.state
            const isCollaborating = formData.hasOwnProperty('collaborate') === false || formData.collaborate === true
            const step = formData?.step || 1
            user['step'] = `${step}. ${petitionerSteps[step]}`
            user['workflowType'] = formData?.type || 'Divorce'
            if (isCollaborating === true && formData.hasOwnProperty('sharingUser') && formData.sharingUser.length > 0)
              user['spouse_email'] = formData.sharingUser
            else
              user['spouse_email'] = 'N/A'
  
            user['collaborate'] = isCollaborating === true ? 'With Spouse' : 'Self'
            if (formData.step === 7) {
              user['completed'] = formData.modifiedDate
              user['signed'] = isCollaborating === false ? formData.modifiedDate : formData.esign.signature.dateStamp
            }
          } else {
            $logger.info(`Could not find workflow for user: ${user.full_name} - ${user.email}`)
          }
        } else {
          const forms = (await db.collection('workflows-user').where('sharingUser', '==', user.email).get()).docs
          if(forms?.length > 0) {
            const formData = forms[0].data()
            user['workflow'] = formData?.state || 'Utah'
            user['step'] = formData.respondentStep + '. ' + respondentSteps[formData.respondentStep - 1]
          } else {
            user['workflow'] = 'Utah'
            user['step'] = 'Unregistered'
          }

          user['collaborate'] = 'With Spouse'
        }

        const collection = (await db.collection('users').doc(user.email).collection('payments').get()).docs.map(doc => {
          const payments = doc.data()
          user['payment_date_1'] = payments?.level_1 || ''
          user['payment_date_2'] = payments?.level_2 || ''
          user = {...user, payments};
        })
        formated.push(user);
      }))

      commit('SET_STATISTICS', formated);
      return formated;
    },
  },
}
