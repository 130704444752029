<template>
  <v-row justify="center" id="form-builder">   
    <v-dialog
      v-model="formBuilderDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :retain-focus="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn 
          v-on="on" 
          fab
          small
          title="Add New Workflow" 
          style="position:absolute;right:10px;top:5%;"
          @click="newForm"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      
      <v-toolbar
        id="workflow-builder-toolbar"
        class="p-fixed z-index-99"
        color="grey lighten-2"
        extended
        extension-height="72"
      >
        <v-toolbar-title style="display:flex;align-items:center;">
          <v-img
            class="mx-2 cursor-pointer"
            max-height="40"
            contain
            position="left center"
            src="@/assets/simple_ending_logo_icon_horizontal.svg"
          />
          <div class="page-title">{{form.name}}</div>
        </v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn title="Save" text @click="saveWorkflow">Save Workflow</v-btn>
        </v-toolbar-items>
        <v-btn title="Close" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <teap-alert />   
      <!-- Stepper Header -->
      <v-stepper
        v-model="currentStep"
        id="workflow-builder-stepper"
        class="o-wcg-stepper-header p-fixed z-index-100"
      >
        <v-stepper-header v-show="!isLoading" class="o-wcg-stepper-header p-fixed full-width">
          <v-stepper-step editable step="1"> Add Metadata</v-stepper-step>

          <v-divider/>

          <v-stepper-step
            editable
            step="2"
            @click="handleBuildFormStep"
          >
            Build Form
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step
            step="3"
            editable
            @click="displayLogicDirty = true"
          >
            Display Logic
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step
            step="4"
            editable
            @click="handleDocumentsStep"
          >
            Documents
            <br/>
            <span class="font-size-x-small">{{ currentDocName }}</span>
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step step="5" editable>
            Rules
            <br/>
            <span class="font-size-x-small">{{ currentRuleName }}</span>
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step step="6" :editable="actionStepEditable" :title="actionStepEditable ? '' : 'Add or change to an ACTION LOGIC rule in order to access actions.' ">
            Actions
            <br/>
            <span class="font-size-x-small">{{ currentActionName }}</span>
          </v-stepper-step>

          <v-divider/>

          <v-stepper-step 
            step="7" 
            editable 
            @click="handlePreviewStep"
          > 
            Preview
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>

      <!-- Stepper Items -->
      <v-stepper
        v-model="currentStep"
        class="o-wcg-stepper-items z-index-98"
        :style="`height: ${contentHeight}px;`"
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card v-if="currentStep === '1'" tile class="mb-6">
              <v-card-title class="bg-primary white--text">
                Metadata
              </v-card-title>
              <v-divider/>
              <v-card-text>
                <v-row>
                  <v-col
                      cols="6"
                      lg="6"
                      class="d-flex align-top justify-center"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12" lg="12">
                          <v-text-field v-model="form.name" label="Form Name"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="12">
                          Children:<br />
                          <v-checkbox
                            v-model="useChildren"
                            label="Use Children?"
                            @change="useChildrenClick($event)"
                          />
                          <v-radio-group 
                            v-if="useChildren"
                            v-model="form.childrenOptions" 
                            :column="false" 
                            @change="handleKidOption"
                          >
                            <v-radio value="both" label="Both" style="margin-right: 10px;" />
                            <v-radio value="with" label="With Kids" style="margin-right: 10px;" />
                            <v-radio value="without" label="Without Kids" style="margin-right: 10px;" />
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="12">
                          <v-select 
                            v-model="form.state" 
                            label="State" 
                            :items="getStates"
                            clearable
                            @change="handleStateClick"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="12">
                          <v-select 
                            v-model="form.type"
                            label="Type" 
                            item-text="title"
                            item-value="title"
                            clearable
                            :items="typeByState(form?.state || '')"
                          />
                          Selected value: {{ form.type }}
                        </v-col>
                      </v-row>                      
                      <v-row v-if="countiesByState(form.state).length > 0 && form.childrenOptions.length > 0">
                        <v-col cols="12" lg="12">
                          <v-checkbox
                            v-model="form.useCounties"
                            label="Use Counties?"
                          />
                          <v-select 
                            v-if="form.useCounties"
                            v-model="form.counties" 
                            label="Counties" 
                            item-disabled="disabled"
                            item-text="name"
                            multiple 
                            chips
                            :closable-chips="true"
                            :items="getCounties" />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="6"
                    lg="6"
                    class="d-flex align-top justify-center"
                  >
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="form.description"
                            label="Form Description"
                          />
                        </v-col>                        
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="form.jurisdiction"
                            label="Jurisdiction"
                          />
                        </v-col>                        
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-row v-if="currentStep === '2'" id="formBuilder">
              <v-col cols="12" md="4">
                <v-card tile>
                  <v-card-title class="bg-primary white--text">Form Elements</v-card-title>

                  <div>
                    <!-- Form Element List -->
                    <MultiOptionAdd
                      :options="getFormElementTypes"
                      selection-label="Element To Add"
                      button-title="Add New Form Element"
                      item-text="name"
                      item-value="name"
                      @add-new-option="addFormElement"
                    />

                    <v-row>
                      <v-col cols="12">
                        <v-tabs
                          v-model="currentTab"
                          background-color="#42b983"
                          dark
                          center-active
                          show-arrows
                        >
                          <v-tabs-slider color="primary"/>
                          <v-tab
                            v-for="(pageName, index) in pageNames"
                            :key="index"
                          >
                            {{ pageName }}
                          </v-tab>
                        </v-tabs>

                        <v-tabs-items id="form-elements" v-model="currentTab">
                          <v-tab-item v-for="pgIndex in pages" :key="pgIndex">
                            <v-text-field
                              v-if="showSearch(pgIndex)"
                              label="Search Form Elements... "
                              v-model="searchFormFields"
                              clearable
                              solo
                              class="a-action-list-filter"
                            />
                            <draggable
                              :list="getFieldsByPageNumber(pgIndex)"
                              :move="checkMove"
                              @end="handleNewOrder($event, pgIndex)"
                            >
                              <template
                                v-for="(field, index) in getFieldsByPageNumber(pgIndex)"
                              >

                                <v-expansion-panels :key="index" accordion class="pr-1">
                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'dialog-window'"                                    
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="announcement"
                                    title="Dialog Window"
                                    unique-title="Admin | Dialog Window Title"
                                    heading="User | Dialog Window Heading"
                                    text="User | Dialog Window Text"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'date-picker'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="date_range"
                                    title="Date Picker"
                                    unique-title="Admin | Date Picker Title"
                                    label="User | Date Picker Label"
                                    :validators="['', 'adult', 'child']"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'heading'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="notes"
                                    title="Heading"
                                    unique-title="Admin | Heading Title"
                                    content="User | Heading Content"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'subheading'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="notes"
                                    title="Subheading"
                                    unique-title="Admin | Subheading Title"
                                    content="User | Subheading Content"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'paragraph'"
                                    :key="index"
                                    :data="field"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="notes"
                                    title="Paragraph"
                                    unique-title="Admin | Paragraph Title"
                                    content="User | Paragraph Content"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'child-support-calculator'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="calculate"
                                    title="Child Support Calculator"
                                    unique-title="Admin | Child Support Calculator"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'youtube-video'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="ondemand_video"
                                    title="YouTube Video"
                                    unique-title="Admin | Video Title"
                                    url="Admin | Video URL"
                                    name="Video Name"
                                    content="User | Video Description"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                    @videoCheck="checkYoutubeVideo(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'page-break'"
                                    :block-drag="true"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="insert_drive_file"
                                    title="Page Break"
                                    unique-title="Admin | Page Break Title"
                                    name="Page Name"
                                    @uniqueCheck="checkForUniqTitle(field.fieldsIndex)"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'file-input'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="cloud_upload"
                                    title="File Input"
                                    unique-title="Admin | File Input Title"
                                    label="User-Facing | File Input Label"
                                    @uniqueCheck="checkForUniqTitle(field.fieldsIndex)"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'text-input-short'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="short_text"
                                    title="Short Text Field"
                                    unique-title="Admin | Text Field Title"
                                    label="User-Facing | Input Label"
                                    :validators="['', 'email','phone','ssn','time']"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'computed-property'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :question-list="formElementsListForMovement"
                                    :bg="'bg-' + field.type"
                                    icon="calculate"
                                    title="Computed Property"
                                    unique-title="Admin | Computed Property Title"
                                    name="User | Label"
                                    prefix="User-Facing | Prefix"
                                    suffix="User-Facing | Suffix"
                                    label="Formula"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                      v-if="field.type === 'grouped-options'"
                                      :key="index"
                                      :data="field"
                                      :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                      :question-list="formElementsListForMovement"
                                      :bg="'bg-' + field.type"
                                      icon="merge"
                                      title="Grouped Options"
                                      unique-title="Admin | Grouped Options Title"
                                      label="User | Label"
                                      @uniqueCheck="checkForUniqTitle()"
                                      @move="moveFormElement(field, $event)"
                                      @remove="removeFormElement(field.fieldsIndex)"
                                      @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'label'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :question-list="formElementsListForMovement"
                                    :bg="'bg-' + field.type"
                                    icon="short_text"
                                    title="Label Field"
                                    unique-title="Admin | Label Field Title"
                                    name="User | Label Name"
                                    label="User-Facing | Label Value"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'signature-pad'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="gesture"
                                    title="Signature Pad"
                                    unique-title="Admin | Signature Title"
                                    label="User-Facing | Signature Pad Label"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'number-input'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="add_box"
                                    title="Number Input"
                                    unique-title="Admin | Number Input Title"
                                    label="User-Facing | Number Input Label"
                                    prefix="User-Facing | Prefix"
                                    suffix="User-Facing | Suffix"
                                    min="User-Facing | Lower Bound"
                                    max="User-Facing | Upper Bound"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'text-input-long'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="short_text"
                                    title="Long Text Field"
                                    unique-title="Admin | Text Field Title"
                                    label="User-Facing | Input Label"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'state-select'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="check_circle"
                                    title="State-Select"
                                    unique-title="Admin | State-Select Title"
                                    label="User-Facing | Input Label"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'single-select'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="check_circle"
                                    title="Single-Select"
                                    unique-title="Admin | Single-Select Title"
                                    label="User-Facing | Input Label"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'multi-select'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="check_circle"
                                    title="Multi-Select"
                                    unique-title="Admin | Multi-Select Title"
                                    label="User-Facing | Input Label"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'item-table'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="table_chart"
                                    title="Item Table"
                                    unique-title="Admin | Item Table Title"
                                    label="User-Facing | Item Table Label"
                                    @uniqueCheck="checkForUniqTitle(field.fieldsIndex)"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />

                                  <FormElement
                                    :id="`form-element-config-${field.uid}`"
                                    v-if="field.type === 'embedded-image'"
                                    :key="index"
                                    :data="field"
                                    :is-unique="isFieldTitleUnique(field.fieldsIndex)"
                                    :bg="'bg-' + field.type"
                                    :questionList="formElementsListForMovement"
                                    icon="image"
                                    title="Embedded Image"
                                    unique-title="Admin | Embedded Image Title"
                                    label="Image(s) Description"
                                    @uniqueCheck="checkForUniqTitle()"
                                    @move="moveFormElement(field, $event)"
                                    @remove="removeFormElement(field.fieldsIndex)"
                                    @duplicate="duplicateFormElement(field.fieldsIndex)"
                                  />
                                </v-expansion-panels>
                              </template>
                            </draggable>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="8">
                <FormBuilderPreview
                  :form="form"
                  :show-switch="true"
                  :switch-label="'Display All Form Elements'"
                  :current-survey-tab="currentSurveyTab"
                  :content-height="contentHeight"
                  @ignoreFormDataChanges="handleIgnoreFormDataChanges($event)"
                  @switch-flip="displayLogicShowAll"
                  @update-current-survey-tab="updateCurrentSurveyTab"
                />
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <DisplayLogicConfiguration
              v-if="currentStep === '3'"
              :key="form.fields && form.fields.length || 'display-logic'"
              :fields="displayLogicFormElementsList"
              :page-names="pageNames"
              :content-height="contentHeight"
              :parsed-question-list="formElementsListForSelection"              
            />
          </v-stepper-content>

          <v-stepper-content step="4">
            <DocumentsConfiguration
              v-if="currentStep === '4'"
              :form="form"
              :doc-combined-pages="getDocCombinedPages"
            />
          </v-stepper-content>

          <v-stepper-content step="5">
            <RulesBuilder
                v-if="currentStep === '5'"
                :form="form"
                :doc-index="currentDocIndex"
            />
          </v-stepper-content>

          <v-stepper-content step="6">
            <ActionsConfiguration
                v-if="currentStep === '6'"
                :pdf-file="templateFile"
                :current-pdf-pages="getDocCombinedPages"
                :content-height="contentHeight"
            />
          </v-stepper-content>

          <v-stepper-content step="7">
            <v-row v-if="currentStep === '7'">
              <v-col cols="12" md="6">
                <FileRenderer :pdf-file="processedFile">
                  <template #card-title>Finished Computed File</template>
                </FileRenderer>
              </v-col>
              <v-col cols="12" md="6">
                <FormBuilderPreview
                  id="form-preview-interactive"
                  ref="form-preview-interactive"
                  :default-value="false"
                  :form="form"
                  :show-button="true"
                  @button-click="updateProcessedFile({ processedFile: true })"
                />
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <!--      </v-card>-->

      <!-- Dialog that pops up when closing the Form Builder without saving (by clicking the X) -->
      <v-dialog
          v-model="closeFormBuilderDialog"
          :retain-focus="false"
          max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Close Without Saving</v-card-title>

          <v-card-text>
            Are you sure you want to close this form without saving? All unsaved work will be lost.
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="green lighten-1"
              text
              @click="closeFormBuilderDialog = false"
            >No
            </v-btn>
            <v-spacer/>
            <v-btn color="red lighten-1" text @click="closeFormBuilder"
            >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog that pops up when attempting to SAVE WORKFLOW in the Form Builder and there are one or more pages uploaded this session that do not have unique names -->
      <v-dialog
        v-model="saveFormBuilderDialog"
        :retain-focus="false"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">
            Pages Without Unique Names
          </v-card-title>

          <v-card-text>
            There are {{ numberNonUniqueNames }} pages added this session with
            non-unique names. Please change the names of those pages before
            saving.
          </v-card-text>

          <v-card-actions>
            <v-spacer/>

            <v-btn
              color="green lighten-1"
              text
              @click="saveFormBuilderDialog = false"
            >Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <div class="text-center">
    <v-dialog
      v-model="confirmationDialog"
      width="500"
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirmation
        </v-card-title>

        <v-card-text class="mt-2 font-weight-bold">
          Are you sure you want delete this form element?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="confirmFormDeleting"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  </v-row>
</template>

<script>
/* eslint-disable no-case-declarations */
/* eslint-disable new-cap */
import formBuilderMixin from '../../mixins/components/formBuilder.mixin';

export default {
  name: "FormBuilder",

  mixins: [formBuilderMixin]
};
</script>