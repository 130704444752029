const { jsPDF } = require("jspdf")
const { applyPlugin } = require("jspdf-autotable")
applyPlugin(jsPDF);
const xIcon = require("@/assets/x-transparent-512.png")
const checkIcon = require("@/assets/check-transparent-214.png")
const { addActionsToTextEditor } = require('@/stores/helpers/addActionsToTextEditor.helper')
const { alignTextEditor } = require("@/stores/helpers/alignTextEditor.helper")
const { setupProcessedFile } = require("@/stores/helpers/setupProcessedFile.helper")
const {
  promiseBlobToDataURL,
  getDataUrlFromImageFormElement
} = require("@/services/workflow/index")
const { getImageHeightWidth } = require("@/utils/get-img-height-width.util")
const { getEndpointTEAP } = require("@/services/gcloud/getProject")
const { setTextEditorFonts } = require('@/utils/set-text-editor-fonts.util')
const { getSignatureType } = require('@/stores/helpers/getSignatureType.helper')
const { getDateFormat } = require("@/utils/getDateFormat.util")
const { actionSize } = require('@/constants/actionSizes')
const expressionatorMethod = require("@/utils/expressionator.util")
const $logger = require('@/utils/logger.util')
const handleParagraphing = require('@/utils/handle-paragraphing.util')
const textEditorPdfInjector = require('@/utils/text-editor-pdf-injector.util')

const pdfGenerator = ({ commit, dispatch, state, rootGetters }, formData, payload, collectionName = 'workflows-user') => {
    return new Promise(async (resolveMain, rejectMain) => {
        const { pdfTemplateImages, pdfOptions } = state        
        const signatureImgMap = {}
        //
        // Sort template pages by page number
        //

        let imagesArray = []
        if (pdfTemplateImages?.length > 0) {
          imagesArray = pdfTemplateImages.sort((a, b) => {
            return (a?.page && b?.page) ? a.page - b.page : 0
          });
        }

        //
        // Load up actions from rules that have their criteria met
        //

        const rules = Object.keys(formData).length > 0 && formData?.docs ? formData?.docs[payload.docIndex]?.rules || {} : {}

        let ACTIONS = await dispatch('getActionsFromRules', rules); // Holds actions that will be added to file        
        const actionsSize = ACTIONS.length;

        let actionPromises = []; // Holds promises returned by some actions (text-editor)

        if (!!payload && payload?.processedFile) {
          // Call textEditorActionParser cloud function for all actions of type 'text-editor' and push into array of promises
          for (let i = 0; i < actionsSize; i++) {
            const currentAction = ACTIONS[i];

            if (currentAction.type === 'text-editor' && currentAction.savedData) {

              let textEditorContent = [{
                type: 'paragraph',
                data: {
                  text: handleParagraphing(currentAction?.quillSavedData)
                }
              }];

              const textEditorNumberingLevels = currentAction?.autoNumberingLevels ?? [];

              let textEditorBlocks = [];
              if(rootGetters['blocks/blocks'].length === 0)
                await dispatch('blocks/setBlocks', { id: formData.id, collectionName }, { root: true })
              
              const blocks = rootGetters['blocks/blocks'] || []
              for (let k = 0; k < blocks.length; k++) {
                const block = blocks[k]

                let tagType = block.type;
                if(tagType === null) continue;
                let questionValue = '';
                if (block.type === 'question-input' && block.value) {
                  questionValue = await dispatch('getQuestionValue', block.value);
                  if (block.value.type === 'item-table') {
                    tagType = 'item-table';
                  }
                } else if(tagType.indexOf('-signature-') > 0) {
                  block.value = {
                    title: tagType,
                    uid: Date.now()
                  }

                  const { tag, value } = getSignatureType(getDateFormat, formData, tagType)
                  tagType = tag
                  questionValue = value
                  if(tagType.indexOf('-img') > 0)
                    signatureImgMap[tagType] = questionValue
                }

                const questionConditionMet = tagType.indexOf('-signature-') > 0 ? true : await dispatch('getQuestionConditionMet', block);             

                const textEditorBlock = {
                  tagUid: block?.value?.uid ?? '',
                  tagTitle: block?.value?.title ?? '',
                  tagType,
                  tagValue: !questionValue ? '' : questionValue,
                  tagName: block.tagName,
                  conditionMet: questionConditionMet
                }
                
                textEditorBlocks.push(textEditorBlock);
              }

              let textEditorActionPromise = new Promise((resolve, reject) => {
                const teaUrl = getEndpointTEAP()
                const teaData = {
                  content: textEditorContent,
                  tags: textEditorBlocks,
                  levels: textEditorNumberingLevels,
                }

                fetch(teaUrl, {
                  method: 'POST',
                  body: JSON.stringify(teaData)
                }).then(response => {
                  response.text().then(text => {
                    currentAction.parsedActionText = addActionsToTextEditor(text);
                    resolve(currentAction);
                  }).catch(error => {
                    reject(error);
                  });
                }).catch(error => {
                  dispatch('teap/setErrorMsg', 'Text Editor Action Error: ' +  error.message, { root: true })
                  reject(error);
                });
              });

              actionPromises.push(textEditorActionPromise);
            }
          }
        }

        await Promise.all(actionPromises).then(async () => {
          // Create new jsPDF object which will eventually be converted into a PDF file
          const pdfWidth = 8.5;
          const pdfHeight = 11.0;

          let newPDF = new jsPDF('p', 'in', [pdfWidth, pdfHeight]);

          const percentToInchesWidth = (pdfWidth / 100);
          const percentToInchesHeight = (pdfHeight / 100);

          const imagesArraySize = imagesArray.length;
          for (let g = 0; g < imagesArraySize; g++) {
            const img = imagesArray[g];
            if (img?.file) {
              newPDF.addImage(img.file, 'JPEG', 0, 0, pdfWidth, pdfHeight); // 0-8.5 width, 0-11 height
            }

            for (let h = 0; h < actionsSize; h++) {
              if (ACTIONS[h].location.page === g + 1) {
                switch (ACTIONS[h].type) {
                  case 'add-x':
                  case 'add-check-mark': {
                    const action = ACTIONS[h];
                    // Calculate the location of the X or ✓
                    const scale = 100;
                    const w = actionSize[action.size].width;
                    const iconSize = (((w / scale) * pdfWidth) * (scale / 2)) / scale;
                    const xStart = (action.location.x * percentToInchesWidth) - iconSize / 4;
                    const yStart = (action.location.y * percentToInchesHeight) - iconSize / 4;

                    if (action.type === 'add-x') {
                      newPDF.addImage(xIcon, 'PNG', xStart, yStart, iconSize, iconSize);
                    } else if (action.type === 'add-check-mark') {
                      newPDF.addImage(checkIcon, 'PNG', xStart, yStart, iconSize, iconSize);
                    }

                    break;
                  }
                  case "resp-signature-date":
                  case "pet-signature-date": {
                    const action = ACTIONS[h];
                    // Set font family
                    setTextEditorFonts(newPDF, action);
                    newPDF.setFontSize(action.fontSize);
    
                    // Set font color
                    const chosenColor = pdfOptions.colors.find(
                      (c) => c.name === action.textColor
                    );
                    const selectedColor = chosenColor
                      ? chosenColor.code
                      : "#000000";
                    newPDF.setTextColor(selectedColor);
    
                    let textY = action.location.startY * percentToInchesHeight;
                    if (action.fontSize) {
                      const lineHeight = parseFloat(action.fontSize) / 72;
    
                      // The line height must be added to the vertical position to get correct positioning of the text
                      textY =
                        action.location.startY * percentToInchesHeight + lineHeight;
                    }
    
                    const { value: textToWrite } = getSignatureType(
                      getDateFormat,
                      formData,
                      action.type
                    );
    
                    alignTextEditor(
                      textToWrite,
                      newPDF,
                      action,
                      percentToInchesWidth,
                      textY
                    );
    
                    break;
                  }
                  case 'text-static':
                  case 'text-from-question': {
                    const action = ACTIONS[h];

                    // Set font family
                    setTextEditorFonts(newPDF, action)

                    // Initialize text that will be pulled into the pdf
                    let textFromQuestion = 'Lorem Ipsum';
                    let textStatic = '';

                    // Set font size
                    newPDF.setFontSize(action.fontSize);

                    // Set font color
                    const chosenColor = pdfOptions.colors.find(
                      (c) => c.name === action.textColor
                    );
                    const selectedColor = chosenColor ? chosenColor.code : '#000000';
                    newPDF.setTextColor(selectedColor);

                    let textY = (action.location.startY * percentToInchesHeight);
                    if (action.fontSize) {
                      const lineHeight = parseFloat(action.fontSize) / 72;

                      // The line height must be added to the vertical position to get correct positioning of the text
                      textY = (action.location.startY * percentToInchesHeight) + lineHeight;
                    }

                    if (action.type === 'text-from-question' && action?.question) {
                      textFromQuestion = await dispatch('getQuestionValue', action.question);
                      textFromQuestion = textFromQuestion ?? '';
                    } else if (action.type === 'text-static') {
                      textStatic = expressionatorMethod(action.staticText, formData.fields) ?? '';
                    }

                    let textToWrite = '';
                    if (action?.type === 'text-from-question') {
                      textToWrite = textFromQuestion || "";
                    } else if (action?.type === 'text-static') {
                      textToWrite = textStatic;
                    }

                    alignTextEditor(textToWrite, newPDF, action, percentToInchesWidth, textY)

                    break;
                  }
                  case 'text-editor': {
                    if (!!payload && payload?.processedFile) {
                      let action = ACTIONS[h];
                      textEditorPdfInjector(newPDF, pdfWidth, pdfHeight, formData, action, signatureImgMap, getDataUrlFromImageFormElement)
                    }

                    break;
                  }
                  case 'custom-image': {
                    let action = ACTIONS[h];
                    // Initialize variables that will carry image data
                    let image;

                    // Put image data into initialized variables
                    switch (action.variant) {
                      case 'image-from-survey':
                        if (action?.question) {
                          const question = action.question;

                          const formElement = formData.fields.find(el => {
                            if (el?.uid && question?.uid && el.uid === question.uid) {
                              return true;
                            } else return el.title === question.title;
                          });

                          image = await getDataUrlFromImageFormElement(formElement);
                        }

                        break;
                      case 'image-from-upload':
                        if (action?.image) {
                          image = await promiseBlobToDataURL(action.image);
                        }
                        break;
                    }

                    // Now that we've got the dataURL(s) from our selected source, place it on PDF
                    if (!!image && !Array.isArray(image)) {
                      const dimensions = getImageHeightWidth(image);
                      const overlayRatio = action.location.height === 0 ? Infinity : action.location.width / action.location.height;

                      // If ratio of width / height for image is greater than same
                      // ratio for template, then match widths
                      if (dimensions.ratio > overlayRatio) {
                        const location = {
                          x: action.location.startX * percentToInchesWidth,
                          y: action.location.startY * percentToInchesHeight,
                          width: action.location.width * percentToInchesWidth,
                          height: dimensions.height * (action.location.width / dimensions.width) * percentToInchesHeight
                        }

                        newPDF.addImage(
                          image,
                          "JPEG",
                          location.x,
                          location.y,
                          location.width,
                          location.height
                        );
                      } else {
                        const location = {
                          x: action.location.startX * percentToInchesWidth,
                          y: action.location.startY * percentToInchesHeight,
                          width: dimensions.width * (action.location.height / dimensions.height) * percentToInchesWidth,
                          height: action.location.height * percentToInchesHeight
                        }

                        newPDF.addImage(
                          image,
                          "JPEG",
                          location.x,
                          location.y,
                          location.width,
                          location.height
                        );
                      }

                    } else if (!!image && Array.isArray(image)) {
                      // TODO: handle when multiple photos are selected
                    }

                    break;
                  }
                  case "pet-signature-img":
                  case "resp-signature-img": {
                    let action = ACTIONS[h];
                    // Initialize variables that will carry image data
                    const { value: whatToWrite } = getSignatureType(
                      getDateFormat,
                      formData,
                      action.type
                    );
                    if(formData?.collaborate && whatToWrite.indexOf(',') > 0) {
                      const dimensions = getImageHeightWidth(whatToWrite);
                      const overlayRatio =
                        action.location.height === 0
                          ? Infinity
                          : action.location.width / action.location.height;
      
                      // If ratio of width / height for image is greater than same
                      // ratio for template, then match widths
                      if (dimensions.ratio > overlayRatio) {
                        const location = {
                          x: action.location.startX * percentToInchesWidth,
                          y: action.location.startY * percentToInchesHeight,
                          width: action.location.width * percentToInchesWidth,
                          height:
                            dimensions.height *
                            (action.location.width / dimensions.width) *
                            percentToInchesHeight,
                        };
      
                        newPDF.addImage(
                          whatToWrite,
                          "JPEG",
                          location.x,
                          location.y,
                          location.width,
                          location.height
                        );
                      } else {
                        const location = {
                          x: action.location.startX * percentToInchesWidth,
                          y: action.location.startY * percentToInchesHeight,
                          width:
                            dimensions.width *
                            (action.location.height / dimensions.height) *
                            percentToInchesWidth,
                          height: action.location.height * percentToInchesHeight,
                        };
      
                        newPDF.addImage(
                          whatToWrite,
                          "JPEG",
                          location.x,
                          location.y,
                          location.width,
                          location.height
                        );
                      }
                    } else {
                      newPDF.setFontSize(12)
                      newPDF.setTextColor('#000000');
                      alignTextEditor(
                        whatToWrite.replace('\t\t\t\t','_______________________________'),
                        newPDF,
                        action,
                        percentToInchesWidth,
                        action.location.startY * percentToInchesHeight
                      );
                    }
                    break;
                  }
                }
              }
            }

            if (g < imagesArray.length - 1) {
              newPDF.addPage();
            }
          }

          let processedFile = newPDF.output('blob');

          await setupProcessedFile(payload, commit, dispatch, processedFile, newPDF)

          resolveMain();
        }).catch(error => {
          console.log(`workflowInEdit / generatePdfFile / Promise.all error: `, error)
          rejectMain(`workflowInEdit / generatePdfFile / Promise.all error: ${error}`);
        });
      });    
}

module.exports = { pdfGenerator }