import axios from 'axios'
import { getProject } from './gcloud/getProject';

export default async function copyStorage({ srcPath, srcBucket, targetPath, targetBucket }) {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }

  const projectUrl = getProject()
  const resp = await axios({
    url: `${projectUrl}/copyStorage`,
    method: 'POST',
    headers: config,
    data: { 
      srcPath,
      srcBucket,
      targetPath,
      targetBucket
    }
  })
  
  return resp
}