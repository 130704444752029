import $logger from "@/utils/logger.util";
import duplicateWorkflowWhenPublished from "@/services/workflow/duplicateWorkflowWhenPublished";
import updateSubCollection from "@/services/updateSubCollection.service";
import { removeSubCollection } from "../services/removeSubCollection.service";
import { pdfOptions } from "@/constants/pdfOptionColors.constants";
import getActionsFromRules from "@/stores/actions/rules.action";
import { pdfGenerator } from "@/stores/actions/pdfGenerator.action";
import getQuestionConditionMet from "@/stores/actions/questions.action";
import questionMatcher from "@/utils/question-matcher.util";
import getValueFromQuestion from "@/utils/get-value-from-question.util";
import { v4 as uuidv4 } from "uuid";
import semver from 'semver'

const defaultState = () => {
  return {
    dialog: null, // TODO replace formBuilderDialog with this
    pdfTemplateImages: [],
    workflowBuilderCurrentRule: 0,
    workflowBuilderCurrentAction: -1,
    pdfOptions,
    templateFile: undefined, // Holds latest version of processed PDF file, but does not render Text Editor actions
    processedFile: undefined, // Holds latest version of fully processed PDF file
  };
};

export const workflowInEdit = {
  namespaced: true,

  state: defaultState(),

  getters: {
    getProcessedFile: (state) => state.processedFile,
    getWorkflowBuilderCurrentRule: (state) => state.workflowBuilderCurrentRule,
    getWorkflowBuilderCurrentAction: (state) =>
      state.workflowBuilderCurrentAction,
  },

  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultState());
    },

    SET_PROCESSED_FILE(state, file) {
      state.processedFile = file;
    },

    SET_TEMPLATE_FILE(state, file) {
      state.templateFile = file;
    },

    SET_PDF_TEMPLATE_IMAGES(state, images) {
      state.pdfTemplateImages = images;
    },

    SET_WORKFLOW_BUILDER_CURRENT_RULE(state, currentRule) {
      state.workflowBuilderCurrentRule = currentRule;
    },

    SET_WORKFLOW_BUILDER_CURRENT_ACTION(state, currentAction) {
      state.workflowBuilderCurrentAction = -1;
      setTimeout(() => {
        state.workflowBuilderCurrentAction = currentAction;
      }, 100);
    },

    DELETE_ACTION(state, payload) {
      payload.rootState.formBuilderActiveForm.docs[
        payload.rootState.formBuilderDocIndex
      ].rules[state.workflowBuilderCurrentRule].actions.splice(
        payload.index,
        1
      );
    },
  },

  actions: {
    getQuestionConditionMet,

    getActionsFromRules,

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    setWorkflowBuilderCurrentRule({ commit }, currentRule) {
      commit("SET_WORKFLOW_BUILDER_CURRENT_RULE", currentRule);
    },

    setWorkflowBuilderCurrentAction({ commit }, currentAction) {
      commit("SET_WORKFLOW_BUILDER_CURRENT_ACTION", currentAction);
    },

    deleteAction({ commit, rootState }, index) {
      commit("DELETE_ACTION", { rootState: rootState, index: index });
    },

    setProcessedFile({ commit }, file) {
      commit("SET_PROCESSED_FILE", file);
    },

    clearProcessedFile({ commit }) {
      commit("SET_PROCESSED_FILE", undefined);
    },

    async setPdfTemplateImages({ commit }, payload) {
      commit("SET_PDF_TEMPLATE_IMAGES", payload);
      return
    },

    async uploadEmbeddedImages({ state, dispatch, rootState }, payload) {
      const storageRef = rootState.firebaseConfig.storage.ref();
      const formId = rootState.formBuilderActiveForm.id;
      const formElementId = payload.field.uid;

      const oldDirectory = "workflows/";
      const newDirectory = "workflows-in-edit/";
      let activeDirectory = oldDirectory;
      let formPath =
        activeDirectory +
        formId +
        "/form-elements/embedded-image/" +
        formElementId +
        "/";

      const res = await storageRef.child("workflows-in-edit").listAll();
      res.prefixes.forEach((workflowId) => {
        if (workflowId.name === formId) {
          activeDirectory = newDirectory;
          formPath = formPath =
            activeDirectory +
            formId +
            "/form-elements/embedded-image/" +
            formElementId +
            "/";
        }
      });

      const activeStorageRequests = [];

      // Delete previous files if any had previously been uploaded
      if (payload.field.imageRefs.length > 0) {
        const itemsToDelete = {
          field: payload.field,
        };
        await dispatch("embeddedImageDelete", itemsToDelete);
      }

      // Iterate over files and push them into storage request at appropriate storage location
      const imagesLength = payload.images.length;
      for (let i = 0; i < imagesLength; i++) {
        const storagePath = formPath + payload.images[i].name;
        const storageRequest = new Promise((resolve, reject) => {
          storageRef
            .child(storagePath)
            .put(payload.images[i])
            .then((snapshot) => {
              resolve(snapshot);
            })
            .catch((error) => {
              reject(error);
            });
        });

        activeStorageRequests.push(storageRequest);
      }

      // Once storage requests are all pushed into activeStorageRequests, get download URLs if upload successful
      return new Promise((resolve, reject) => {
        Promise.all(activeStorageRequests)
          .then((snapshots) => {
            const activeDownloadRequests = [];
            const imageRefs = [];
            snapshots.forEach((item) => {
              let imageData = {
                name: item.metadata.name,
                ref: item.metadata.fullPath,
                url: null,
                title: null,
                description: null,
              };
              const downloadRequest = item.ref
                .getDownloadURL()
                .then((downloadURL) => {
                  imageData.url = downloadURL;
                  imageRefs.push(imageData);
                })
              activeDownloadRequests.push(downloadRequest);
            });

            Promise.all(activeDownloadRequests)
              .then(() => {
                // Include data to be able to update appropriate location
                const formUpdate = {
                  field: {
                    title: payload.field.title,
                    type: payload.field.type,
                  },
                  updates: {
                    imageRefs: imageRefs,
                  },
                };

                dispatch("updateWorkflowField", formUpdate, { root: true });
                resolve(imageRefs);
              })
              .catch((error) => {
                reject("getDownloadURL Promise.all error: ", error);
              });
          })
          .catch((error) => {
            reject("uploadEmbeddedImages Promise.all error: ", error);
          });
      });
    },

    embeddedImageDelete({ dispatch, rootState }, payload) {
      const storageRef = rootState.firebaseConfig.storage.ref();
      const activeDeleteRequests = [];

      const deleteRequestsLength = payload.field.imageRefs.length;
      for (let i = 0; i < deleteRequestsLength; i++) {
        activeDeleteRequests.push(
          storageRef.child(payload.field.imageRefs[i].ref).delete()
        );
      }

      Promise.all(activeDeleteRequests)
        .then(() => {
          // Include data to be able to update appropriate location
          const formUpdate = {
            field: {
              title: payload.field.title,
              type: payload.field.type,
            },
            updates: {
              imageRefs: [],
            },
          };

          dispatch("updateWorkflowField", formUpdate, { root: true });
        })
    },

    async generatePdfFile(
      { commit, dispatch, state, rootState, rootGetters },
      payload
    ) {
      await pdfGenerator(
        { commit, dispatch, state, rootGetters },
        rootState.formBuilderActiveForm,
        payload,
        "forms"
      )
      return
    },

    async updateRawForm(
      { rootState, commit },
      { formName = "forms", formId, data }
    ) {
      await rootState.firebaseConfig.db
        .collection(formName)
        .doc(formId)
        .set(data, { merge: true });
      return;
    },

    getQuestionValue({ rootState }, question) {
      return getValueFromQuestion(
        rootState.formBuilderActiveForm.fields,
        question
      );
    },

    checkQuestionForMatch({ rootState }, logicQuestion) {
      return questionMatcher(rootState.formBuilderActiveForm, logicQuestion);
    },

    rejectWorkflow({ rootState }, payload) {
      rootState.firebaseConfig.db
        .collection("forms")
        .doc(payload.id)
        .set(payload, { merge: true })
        .then(() => {
          $logger.info(`Successfully rejected workflow id: ${payload.id}`);
        })
    },

    updateStakeholderStatus({ rootState }, payload) {
      rootState.firebaseConfig.db
        .collection("forms")
        .doc(payload.id)
        .set(payload, { merge: true })
        .then(() => {
          $logger.info("Successfully updated stake holder status");
        })
    },

    async publishWorkflow({ rootState, dispatch, rootGetters }, payload) {
      const db = rootState.firebaseConfig.db;
      const editedWorkflow = db.collection("forms").doc(payload.id);
      const publishedRef = db.collection("workflows-published");
      const oldPublishedWorkflow = await publishedRef
        .where("parentId", "==", payload.id)
        .get();
      let newId = uuidv4();
      if (!oldPublishedWorkflow.empty) {
        newId = oldPublishedWorkflow.docs[0].data().id;
      }

      const formData = (await editedWorkflow.get()).data()
      const ds = Date.now();
      formData.stakeholders.forEach((sh) => {
        (sh.status = "Approved"), (sh.statusDateStamp = ds);
      });

      const formStatusUpdate = {
        status: "Approved",
        statusDateStamp: ds,
        stakeholders: formData.stakeholders,
        state: payload.state,
        version: semver.inc(formData.version, 'major')
      };

      try {
        await editedWorkflow.set(formStatusUpdate, { merge: true });
      } catch (error) {
        throw "*** could not update workflow status"
      }

      const parent = "workflows-published";
      await Promise.all([
        removeSubCollection({ parent, id: newId, sub: 'display-logic-elements' }),
        removeSubCollection({ parent, id: newId, sub: 'display-logic-library' }),
        removeSubCollection({ parent, id: newId, sub: 'fields' })
      ])
      await duplicateWorkflowWhenPublished(payload.id, newId);
      const docs = (await editedWorkflow.collection("docs").get()).docs.map(
        (d) => ({ ...d.data() })
      );

      if (docs.length === 0)
        $logger.info(
          "*** workflowEdit->publishWorkflow->doc size after reading from form id: " +
            payload.id,
          docs.length
        );
        
      await dispatch(
        "blocks/setBlocks",
        { id: payload.id, collectionName: "forms" },
        { root: true }
      );
      const blocks = rootGetters["blocks/blocks"];

      const displayLogicElements = (await editedWorkflow.collection("display-logic-elements").get()).docs.map((d) => ({ ...d.data() }));

      const displayLogicLibrary = (await editedWorkflow.collection("display-logic-library").get()).docs.map((d) => ({ ...d.data() }));

      const toUpdate = [
        updateSubCollection({ parent, id: newId, sub: "blocks", data: blocks }),
        updateSubCollection({
          parent,
          id: newId,
          sub: "display-logic-elements",
          data: displayLogicElements,
        }),
        updateSubCollection({
          parent,
          id: newId,
          sub: "display-logic-library",
          data: displayLogicLibrary,
        })
      ]
      await Promise.all(toUpdate)
      return;
    },
  },
};
