<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card tile class="mb-6">
          <v-card-title class="bg-primary white--text">
            Documents
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2">
              <v-col
                  cols="11"
                  class="d-flex justify-center align-center"
              >
                <v-row v-if="!editDocument">
                  <v-col
                      cols="9"
                      class="d-flex justify-center align-center"
                  >
                    <v-select
                      v-model="currentDocIndex"
                      :items="getActiveFormDocs"
                      id="doc-selector"
                      label="Selected Document"
                      item-text="title"
                      item-value="id"
                      solo
                      class="solo-height-fix"
                      @change="documentChange"
                    />
                  </v-col>
                  <v-col
                    v-if="getActiveFormDocs.length > 1"
                    cols="3"
                    class="d-flex justify-space-between align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          small
                          @click="editDocument = true"
                        >
                          <v-icon small dark>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Document Name</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="red"
                            fab
                            small
                            @click="documentConfirmationDialog = true"
                        >
                          <v-icon small dark color="white">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Current Document</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs}">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="green"
                            fab
                            small
                            @click="showReorderDocsDialog.visible = true"
                        >
                          <v-icon small dark color="white">
                            mdi-order-alphabetical-ascending
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Reorder Documents</span>
                    </v-tooltip>

                  </v-col>
                </v-row>

                <v-row v-else-if="editDocument && !createDocument">
                  <v-col
                      cols="12"
                      md="9"
                      class="d-flex justify-center align-center"
                  >
                    <v-text-field
                      :autofocus="true"
                      v-model="documentOptions.name"
                      label="Enter Document Name"
                      outlined
                      class="solo-height-fix"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="2"
                      class="d-flex justify-center align-center"
                  >
                    <v-btn
                        class="d-inline pa-2"
                        fab
                        x-small
                        @click="editCurrentDocument"
                        title="Edit Document"
                    >
                      <v-icon small dark>mdi-floppy</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <template v-if="!createDocument && !editDocument">
                <v-col cols="1" class="d-flex align-items-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs}">
                      <v-btn
                          v-bind="attrs"
                          v-on="on"
                          title="Add New Document"
                          fab
                          small
                          @click="createDocument = true"
                      >
                        <v-icon> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add New Document</span>
                  </v-tooltip>

                </v-col>
              </template>

              <template v-if="createDocument && !editDocument">
                <v-col cols="12">
                  <div class="elevation-2 pa-4 grey lighten-3">
                    <v-row>
                      <v-col
                          cols="12"
                          sm="6"
                          order-sm="2"
                          class="d-flex justify-end align-start"
                      >
                        <v-btn icon @click="createDocument = false">
                          <v-icon>clear</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="6" order-sm="1">
                        <v-select
                            v-model="documentOptions.selectedType"
                            :items="documentOptions.types"
                            label="New Document Type"
                            solo
                            class="solo-height-fix"
                        />
                      </v-col>
                      <v-col cols="10" order="3">
                        <v-text-field
                          v-model="documentOptions.name"
                          label="Enter Document Name"
                          :autofocus="true"
                        />
                      </v-col>
                      <v-col
                          cols="2"
                          order="4"
                          class="d-flex justify-end align-items-center"
                      >
                        <v-btn
                            class="d-inline pa-2"
                            fab
                            small
                            @click="addNewDocument"
                            title="Save"
                        >
                          <v-icon small dark>mdi-floppy</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </template>
            </v-row>

            <v-row>
              <v-col v-if="hasExistingPages" cols="12">
                <PageCards
                  :pages="getDocTemplate.existingPages"
                  title="Existing Pages"
                  background-color="grey lighten-3"
                  @remove="removeExistingPage($event)"
                  @update="updateExistingPages($event)"
                  @move="activateDocPageMoveDialog($event, 'existing')"
                />
              </v-col>
              <v-col cols="12" v-if="hasCurrentSessionPages">
                <PageCards
                  :name-editable="true"
                  :pages="getDocTemplate.currentSessionPages"
                  title="Pages Added This Session"
                  @remove="removeCurrentSessionPage($event)"
                  @update="updateCurrentSessionPages($event)"
                  @move="activateDocPageMoveDialog($event, 'current-session')"
                  @change-name="activatePageNameDialog($event, 'current-session-pages')"
                />
              </v-col>
              <v-col cols="12">
                <v-divider/>
              </v-col>
              <v-col cols="0" md="1"/>
              <v-col cols="12" md="4">
                <v-btn @click="choosePageUpload"
                >Add New Page As Image
                </v-btn
                >
                <v-file-input
                  id="add-next-page"
                  label="Add Next Page"
                  outlined
                  :accept="getAcceptedUploadFileTypes"
                  prepend-icon=""
                  hide-input
                  @change="addNewPage($event)"
                />
              </v-col>
              <v-col cols="0" md="2"/>
              <v-col cols="12" md="4" style="display:none;">
                <v-btn @click="addNewPage(undefined)"
                >Add New Blank Page
                </v-btn
                >
              </v-col>
              <v-col cols="0" md="1"/>
            </v-row>
          </v-card-text>
        </v-card>

        <DocDisplayLogic 
          v-if="showDocDisplayLogic"
          :display-logic="form.docs[currentDocIndex].displayLogic"
          :form-fields="form.fields"
          @update="() => {form.docs[currentDocIndex].displayLogic = $event}"
        />        
      </v-col>
      <v-col cols="12" md="4">
        <FileRenderer :pdf-file="processedFile">
          <template #card-title>Document Preview</template>
        </FileRenderer>
      </v-col>
    </v-row>

    <!-- Dialog to change the page number -->
    <v-dialog
      v-model="pdfTemplatePageNumber.dialog"
      :retain-focus="false"
      max-width="300"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="0" md="2"/>
            <v-col cols="12" md="8">
              <h3>Select where to move this page to.</h3>
              <br/>
              <v-select
                v-model="pdfTemplatePageNumber.pageSelection"
                :items="pdfTemplatePagesItems"
                item-text="moveToTitle"
                item-value="page"
                label="Move Page To"
              />
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            :disabled="pdfTemplatePageNumber.pageSelection === null"
            color="green lighten-1"
            text
            @click="acceptDocPageMovement"
          >
            Move
          </v-btn>

          <v-btn color="red lighten-1" text @click="declineDocPageMovement">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to change the name of pages added this session -->
    <v-dialog
      v-model="pdfTemplatePageName.dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="dissmisModal"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="0" md="2"/>
            <v-col cols="12" md="8">
              <h3>
                Name must be unique within the scope of all template images.
              </h3>
              <br/>
              <h4
                v-if="!pdfTemplatePageName.updatedNameIsUnique"
                class="red--text"
              >
                The name you attempted to accept is already in use.
              </h4>
              <v-text-field
                v-model="pdfTemplatePageName.updatedName"
                :prepend-icon="
                  pdfTemplatePageName.updatedNameIsUnique
                    ? 'check_circle_outline'
                    : 'close'
                "
                label="PDF Template Image Name"
                @keyup="checkPdfTemplateName"
              />
            </v-col>
            <v-col cols="0" md="2"/>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            :disabled="!pdfTemplatePageName.updatedNameIsUnique"
            color="green lighten-1"
            text
            @click="acceptPdfTemplateName"
          >
            Accept Name
          </v-btn>

          <v-btn color="red lighten-1" text @click="declinePdfTemplateName">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="documentConfirmationDialog" :retain-focus="false" max-width="500">
      <v-card class="pa-3">
        <v-card-title class="headline">
          <v-icon color="error" class="mr-2">error</v-icon>
          Delete {{ getActiveFormDocs[currentDocIndex].title }} ?
        </v-card-title>

        <v-card-text>
          Are you sure you want to delete this document? <br />
          Changes will be permanent <strong>only after you save Workflow</strong>.
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text @click="documentConfirmationDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn class="error" text @click="deleteCurrentDocument">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <reorder-documents-dialog 
      v-if="showReorderDocsDialog.visible"
      :form-id="form.id"
      :docs="[...form.docs]"
      :dialog="showReorderDocsDialog"
      @reorder="(form.docs = $event)" />
  </div>
</template>

<script>
import documentConfigurationMixin from "@/mixins/components/documentConfiguration.mixin";

export default {
  name: 'DocumentsConfiguration',

  mixins: [documentConfigurationMixin],
}
</script>
